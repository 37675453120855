
// HEADER VARS
// ==============================
$nav-header-height: 50px !default // set back to 50 when building fcc


// SIDE NAVIGATION VARS
// ==============================

$app-menu-expanded-width: 300px !default
$app-menu-shrinked-width: 60px !default
$app-menu-logo-height: 90px !default
$app-menu-toggle-navbar-icon: 50px !default


// SCROLLBAR
// ===============================

$scroll-bar-width: 5px !default

// Spacing
$spacing-base: 15px
