// LINKS
// ==============================

$link-color: $brand-color !default

a 
    text-decoration: none
    @include themify($themes) 
        color: themed('mainColor')  
    font-weight: 400
    transition: opacity .1s ease-out

    &:focus,
    &:hover 
        opacity: 0.75
    

    &:active 
        opacity: 1
    

