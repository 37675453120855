.daterangepicker
  position: absolute
  width: 278px
  max-width: none
  padding: 0
  margin-top: 7px
  top: 100px
  left: 20px
  z-index: 3001
  display: none
  font-family: arial
  font-size: 15px
  line-height: 1em
  box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 5px 0px
  background-color: $color-white
  border-width: 1px
  border-style: solid
  border-color: rgb(232, 232, 232)
  outline: transparent


  

  &.opensleft
    &:before
      right: 9px

    &:after
      right: 10px

  &.openscenter
    &:before, &:after
      left: 0
      right: 0
      width: 0
      margin-left: auto
      margin-right: auto

  &.opensright
    &:before
      left: 9px

    &:after
      left: 10px

  &.drop-up
    margin-top: -7px

    &:before
      top: initial
      bottom: -7px
      border-bottom: initial
      border-top: 7px solid #ccc

    &:after
      top: initial
      bottom: -6px
      border-bottom: initial
      border-top: 6px solid #fff

  &.single
    .daterangepicker .ranges, .drp-calendar
      float: none

    .drp-selected
      display: none

  &.show-calendar
    .drp-calendar, .drp-buttons
      display: block

  &.auto-apply .drp-buttons
    display: none

  .drp-calendar
    display: none
    max-width: 270px

    &.left
      padding: 8px 0 8px 8px

    &.right
      padding: 8px

    &.single .calendar-table
      border: none

  .calendar-table
    .next span, .prev span
      color: #fff
      border: solid black
      border-width: 0 2px 2px 0
      border-radius: 0
      display: inline-block
      padding: 3px

    .next span
      transform: rotate(-45deg)
      -webkit-transform: rotate(-45deg)

    .prev span
      transform: rotate(135deg)
      -webkit-transform: rotate(135deg)

    th, td
      white-space: nowrap
      text-align: center
      vertical-align: middle
      min-width: 32px
      width: 32px
      height: 24px
      line-height: 24px
      font-size: 12px
      border: 1px solid transparent
      white-space: nowrap
      cursor: pointer
      padding: 3px

    border: 1px solid #fff
    background-color: #fff

    table
      width: 100%
      margin: 0
      border-spacing: 0
      border-collapse: collapse

  td.available:hover, th.available:hover
    background-color: #eee
    border-color: transparent
    color: inherit

  td.week, th.week
    font-size: 80%
    color: #ccc

  td
    &.off
      background-color: #fff
      border-color: transparent
      color: #999

      &.in-range, &.start-date, &.end-date
        background-color: #fff
        border-color: transparent
        color: #999

    &.in-range
      background-color: #ebf4f8
      border-color: transparent
      color: #000
      border-radius: 0

    &.active
      @include themify($themes) 
        background-color: themed('mainColor')
      border-color: transparent
      color: #fff

      &:hover
        @include themify($themes) 
          background-color: themed('mainColor')
        border-color: transparent
        color: #fff

  th.month
    width: auto

  td.disabled, option.disabled
    color: #999
    cursor: not-allowed
    text-decoration: line-through

  select
    display: inline-block
    &.monthselect, &.yearselect
      font-size: 12px
      padding: 1px
      height: auto
      margin: 0
      cursor: default

    &.monthselect
      margin-right: 2%
      width: 56%

    &.yearselect
      width: 40%

    &.hourselect, &.minuteselect, &.secondselect, &.ampmselect
      width: 50px
      margin: 0 auto
      background: #eee
      border: 1px solid #eee
      padding: 2px
      outline: 0
      font-size: 12px

  .calendar-time
    text-align: center
    margin: 4px auto 0 auto
    line-height: 30px
    position: relative

    select.disabled
      color: #ccc
      cursor: not-allowed

  .drp-buttons
    clear: both
    text-align: right
    padding: 8px
    border-top: 1px solid #ddd
    display: none
    line-height: 12px
    vertical-align: middle

  .drp-selected
    display: inline-block
    font-size: 12px
    padding-right: 8px

  .drp-buttons .btn
    height: 30px
    line-height: 30px
    padding: 0 15px
    font-size: 12px
    @include themify($themes) 
      color: themed('secondaryColor')
      border-color: themed('secondaryColor')
      margin-bottom: 5px
    
    &.cancelBtn
      border: none


  &.show-ranges
    &.single
      &.rtl .drp-calendar.left
        border-right: 1px solid #ddd

      &.ltr .drp-calendar.left
        border-left: 1px solid #ddd

    &.rtl .drp-calendar.right
      border-right: 1px solid #ddd

    &.ltr .drp-calendar.left
      border-left: 1px solid #ddd

  .ranges
    float: none
    text-align: left
    margin: 0

  &.show-calendar .ranges
    margin-top: 8px

  .ranges
    ul
      list-style: none
      margin: 0 auto
      padding: 0
      width: 100%

    li
      font-size: 12px
      padding: 8px 12px
      cursor: pointer

      &:hover
        background-color: #eee

      &.active
        @include themify($themes) 
          background-color: themed('mainColor')
        color: #fff

/*  Larger Screen Styling
@media (min-width: 564px)
  .daterangepicker
    width: auto

    .ranges ul
      width: 140px

    &.single
      .ranges ul
        width: 100%

      .drp-calendar.left
        clear: none

      .ranges, .drp-calendar
        float: left

    direction: ltr
    text-align: left

    .drp-calendar
      &.left
        clear: left
        margin-right: 0

        .calendar-table
          border-right: none
          border-top-right-radius: 0
          border-bottom-right-radius: 0

      &.right
        margin-left: 0

        .calendar-table
          border-left: none
          border-top-left-radius: 0
          border-bottom-left-radius: 0

      &.left .calendar-table
        padding-right: 8px

    .ranges, .drp-calendar
      float: left

@media (min-width: 730px)
  .daterangepicker
    .ranges
      width: auto
      float: left

    &.rtl .ranges
      float: right

    .drp-calendar.left
      clear: none !important