.ag-header
	border-bottom: none !important

.sidebar-menu-inner
	li
		a
			padding: 3px

@media screen and (max-height: 768px)
	.sidebar-menu-inner
		li
			a
				padding: 3px

.devicesDetailModalContent
	.modal-body
		display: flex
		flex-direction: column
		height: 100%

		> .row.members-list
			height: 100%
			overflow: hidden

			> .col
				height: 100%
				overflow-y: auto

@keyframes shake2
	from,
	to
		transform: translate3d(0, 0, 0)

	20%,
	60%
		transform: translate3d(-10px, 0, 0)

	40%,
	80%
		transform: translate3d(10px, 0, 0)

.error.ng-hide
	display: block !important
	opacity: 0

.error
	color: $color-offline

	p
		margin: 5px 0
		display: flex
		align-items: center

		i
			margin-right: 15px

.error-add-active
	animation: shake2 .2s
	opacity: 1

#twoFactorForm.ng-enter
	animation: fadeInRight .5s

input:-webkit-autofill
	animation-name: onAutoFillStart

input:not(:-webkit-autofill)
	animation-name: onAutoFillCancel