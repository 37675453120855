/**
  * ---
  *
  * title: Status Dropdown
  * desc: 
  *
  * htmlCodeVariations:
  * - title: Standard
  *   code: |
  *             <status-dropdown
  *                 states="$ctrl.states"
  *                 selected-status="$ctrl.selectedStatus">
  *             </status-dropdown>
  *             <p>Selectd Status: {{$ctrl.selectedStatus}}</p>
  *     
  *
  * jsCodeVariations:
  * - title: Standard
  *   code: |
  *     $ctrl.selectedStatus = 'open';
  *     $ctrl.states = {'open' : 'tag-red', 'edited' : 'tag-orange', 'done' : 'tag-green'}
  *    
  * 
  * usage:
  *  - title: When to use
  *    description:
  * 
  * attributes:
  * - attributeName: 
  *   type: 
  *   required:
  *   description: 
  *   default: 
  *  
  * classes:
  * - className:
  *   required : 
  *   description: 
  *
  * fineprint:
  *   version: 
  *   update: 
  *   owner: Magdalena Riecken
  * ---
  */