$__iconfont__data: map-merge(if(global_variable_exists('__iconfont__data'), $__iconfont__data, ()), (
	"iconfont": (
		"headset-solid": "\ea01",
		"icon-AAAABasic": "\ea02",
		"icon-CodeComparison": "\ea03",
		"icon-Documentation": "\ea04",
		"icon-ERRORUI_template": "\ea05",
		"icon-Email": "\ea06",
		"icon-GPS": "\ea07",
		"icon-IMAGEMEDIATASKUI_template": "\ea08",
		"icon-IMAGEMEDIAUI_template": "\ea09",
		"icon-IMAGETASKUI_template": "\ea0a",
		"icon-IMAGETEXTUI_ALTERNATIVE_template": "\ea0b",
		"icon-IMAGETEXTUI_ORIGINAL_template": "\ea0c",
		"icon-IMAGETEXTUI_template": "\ea0d",
		"icon-IMAGETITLEUI_template": "\ea0e",
		"icon-IMAGEUI_template": "\ea0f",
		"icon-INFOUI_template": "\ea10",
		"icon-MEDIATEXTUI_NOCONTROLS_template": "\ea11",
		"icon-MEDIATEXTUI_template": "\ea12",
		"icon-MEDIAVIEWUI_NOCONTROLS_template": "\ea13",
		"icon-MEDIAVIEWUI_template": "\ea14",
		"icon-MEDIAVIEW_template": "\ea15",
		"icon-PaginatedText": "\ea16",
		"icon-QUESTIONUI_template": "\ea17",
		"icon-Questionnaire": "\ea18",
		"icon-RECORDUI_template": "\ea19",
		"icon-SCANUI_template": "\ea1a",
		"icon-SCREENUI_template": "\ea1b",
		"icon-SUCCESSUI_template": "\ea1c",
		"icon-Selector": "\ea1d",
		"icon-Sensor": "\ea1e",
		"icon-Skype": "\ea1f",
		"icon-SpeechToText": "\ea20",
		"icon-TEXTUI_template": "\ea21",
		"icon-VALIDATEUI_template": "\ea22",
		"icon-WARNINGUI_template": "\ea23",
		"icon-add-step": "\ea24",
		"icon-add-transition": "\ea25",
		"icon-add": "\ea26",
		"icon-address-book-solid": "\ea27",
		"icon-address-book": "\ea28",
		"icon-advanced-editor": "\ea29",
		"icon-ai-dataset-tab": "\ea2a",
		"icon-ai-home-tab": "\ea2b",
		"icon-ai-inference-tab": "\ea2c",
		"icon-ai-trainings-tab": "\ea2d",
		"icon-ai-txt": "\ea2e",
		"icon-ai-wizard-11": "\ea2f",
		"icon-ai-wizard-background-changing": "\ea30",
		"icon-ai-wizard-background-same": "\ea31",
		"icon-ai-wizard-bright_light": "\ea32",
		"icon-ai-wizard-dimmed_light": "\ea33",
		"icon-ai-wizard-distance-0": "\ea34",
		"icon-ai-wizard-distance-1": "\ea35",
		"icon-ai-wizard-distance-2": "\ea36",
		"icon-ai-wizard-distance-3": "\ea37",
		"icon-ai-wizard-distance-middle_distance": "\ea38",
		"icon-ai-wizard-general": "\ea39",
		"icon-ai-wizard-lighttype-artificial_light": "\ea3a",
		"icon-ai-wizard-lighttype-natural_light": "\ea3b",
		"icon-ai-wizard-location-indoor": "\ea3c",
		"icon-ai-wizard-location-outdoor": "\ea3d",
		"icon-ai-wizard-specific-highlight": "\ea3e",
		"icon-ai-wizard-specific": "\ea3f",
		"icon-ai": "\ea40",
		"icon-aistudio-logo-fm": "\ea41",
		"icon-aistudio-white": "\ea42",
		"icon-android": "\ea43",
		"icon-angle-double-down": "\ea44",
		"icon-angle-double-left": "\ea45",
		"icon-angle-double-right": "\ea46",
		"icon-angle-double-up": "\ea47",
		"icon-angle-down": "\ea48",
		"icon-angle-left": "\ea49",
		"icon-angle-right": "\ea4a",
		"icon-angle-up": "\ea4b",
		"icon-apple": "\ea4c",
		"icon-application-management": "\ea4d",
		"icon-arrow-down": "\ea4e",
		"icon-arrow-left": "\ea4f",
		"icon-arrow-right": "\ea50",
		"icon-arrow-up": "\ea51",
		"icon-arrows-alt": "\ea52",
		"icon-ban": "\ea53",
		"icon-bar-chart": "\ea54",
		"icon-barcode": "\ea55",
		"icon-bars": "\ea56",
		"icon-beer": "\ea57",
		"icon-bell": "\ea58",
		"icon-bicycle": "\ea59",
		"icon-book-alt": "\ea5a",
		"icon-book": "\ea5b",
		"icon-bounding-box-stars": "\ea5c",
		"icon-bounding-box-triangle": "\ea5d",
		"icon-box-solid": "\ea5e",
		"icon-browser": "\ea5f",
		"icon-building": "\ea60",
		"icon-calendar": "\ea61",
		"icon-call-history": "\ea62",
		"icon-camera-toggle": "\ea63",
		"icon-camera": "\ea64",
		"icon-caret-down": "\ea65",
		"icon-caret-right": "\ea66",
		"icon-certificate": "\ea67",
		"icon-chart-bar": "\ea68",
		"icon-check-circle-filled": "\ea69",
		"icon-check-circle-o": "\ea6a",
		"icon-check-circle": "\ea6b",
		"icon-check-square-o": "\ea6c",
		"icon-check-square-solid": "\ea6d",
		"icon-check": "\ea6e",
		"icon-checkbox-unchecked": "\ea6f",
		"icon-chevron-down": "\ea70",
		"icon-chevron-left": "\ea71",
		"icon-chevron-right": "\ea72",
		"icon-chevron-up": "\ea73",
		"icon-circle-filled": "\ea74",
		"icon-circle-o-filled": "\ea75",
		"icon-circle": "\ea76",
		"icon-classification-project": "\ea77",
		"icon-clock-circle-filled": "\ea78",
		"icon-clock-o": "\ea79",
		"icon-cloud-check": "\ea7a",
		"icon-cloud-upload": "\ea7b",
		"icon-cloud-x": "\ea7c",
		"icon-cog": "\ea7d",
		"icon-cogs-solid": "\ea7e",
		"icon-cogs": "\ea7f",
		"icon-comment-out": "\ea80",
		"icon-comment": "\ea81",
		"icon-comments": "\ea82",
		"icon-compress-arrows-alt": "\ea83",
		"icon-copy": "\ea84",
		"icon-cross-thin": "\ea85",
		"icon-crosshairs": "\ea86",
		"icon-cubes": "\ea87",
		"icon-dashboard": "\ea88",
		"icon-database": "\ea89",
		"icon-delete": "\ea8a",
		"icon-desktop": "\ea8b",
		"icon-done": "\ea8c",
		"icon-dot-circle-alt": "\ea8d",
		"icon-dot-circle": "\ea8e",
		"icon-dot": "\ea8f",
		"icon-download": "\ea90",
		"icon-edit": "\ea91",
		"icon-ellipsis-h-solid": "\ea92",
		"icon-ellipsis-h": "\ea93",
		"icon-ellipsis-v": "\ea94",
		"icon-envelope": "\ea95",
		"icon-eraser": "\ea96",
		"icon-error": "\ea97",
		"icon-exchange-alt-solid": "\ea98",
		"icon-exchange-solid": "\ea99",
		"icon-exclamation-circle": "\ea9a",
		"icon-exclamation-triangle": "\ea9b",
		"icon-external-link": "\ea9c",
		"icon-eye-slash": "\ea9d",
		"icon-eye": "\ea9e",
		"icon-file-alt": "\ea9f",
		"icon-file-archive": "\eaa0",
		"icon-file-audio-o": "\eaa1",
		"icon-file-audio": "\eaa2",
		"icon-file-code": "\eaa3",
		"icon-file-excel-o": "\eaa4",
		"icon-file-excel": "\eaa5",
		"icon-file-html": "\eaa6",
		"icon-file-image-o": "\eaa7",
		"icon-file-image": "\eaa8",
		"icon-file-js": "\eaa9",
		"icon-file-json": "\eaaa",
		"icon-file-o": "\eaab",
		"icon-file-pdf-alt": "\eaac",
		"icon-file-pdf-o": "\eaad",
		"icon-file-pdf": "\eaae",
		"icon-file-powerpoint-o": "\eaaf",
		"icon-file-powerpoint": "\eab0",
		"icon-file-text-o": "\eab1",
		"icon-file-text": "\eab2",
		"icon-file-upload-arrow": "\eab3",
		"icon-file-upload-cloud": "\eab4",
		"icon-file-upload-solid": "\eab5",
		"icon-file-video": "\eab6",
		"icon-file-word-solid": "\eab7",
		"icon-file-word": "\eab8",
		"icon-file": "\eab9",
		"icon-files": "\eaba",
		"icon-filter-filled": "\eabb",
		"icon-filter": "\eabc",
		"icon-flip-horizontally": "\eabd",
		"icon-flip-vertically": "\eabe",
		"icon-floppy-o": "\eabf",
		"icon-fluent_zoom_in_24_regular": "\eac0",
		"icon-fluent_zoom_out_24_regular": "\eac1",
		"icon-folder": "\eac2",
		"icon-frontline": "\eac3",
		"icon-generic": "\eac4",
		"icon-glasses": "\eac5",
		"icon-globe": "\eac6",
		"icon-hand-o-up": "\eac7",
		"icon-hand-paper": "\eac8",
		"icon-hand-pointer": "\eac9",
		"icon-hand": "\eaca",
		"icon-hands-helping": "\eacb",
		"icon-hd": "\eacc",
		"icon-hide-grid": "\eacd",
		"icon-hide-labels": "\eace",
		"icon-hmd": "\eacf",
		"icon-hourglass": "\ead0",
		"icon-ic_icon-hide-map-o": "\ead1",
		"icon-ic_icon-hide-map": "\ead2",
		"icon-ic_icon-map-o": "\ead3",
		"icon-icon-media": "\ead4",
		"icon-ifelse": "\ead5",
		"icon-image-classification": "\ead6",
		"icon-image": "\ead7",
		"icon-indent": "\ead8",
		"icon-individual-template": "\ead9",
		"icon-info-circle-solid": "\eada",
		"icon-info-circle": "\eadb",
		"icon-info": "\eadc",
		"icon-keyboard-o": "\eadd",
		"icon-language": "\eade",
		"icon-layout-models": "\eadf",
		"icon-legal": "\eae0",
		"icon-license": "\eae1",
		"icon-lightbulb": "\eae2",
		"icon-line": "\eae3",
		"icon-link": "\eae4",
		"icon-list-solid": "\eae5",
		"icon-list": "\eae6",
		"icon-lock": "\eae7",
		"icon-long-arrow-alt-top-right-l": "\eae8",
		"icon-long-arrow-alt-top-right": "\eae9",
		"icon-long-arrow-down": "\eaea",
		"icon-long-arrow-up": "\eaeb",
		"icon-loop": "\eaec",
		"icon-map-o": "\eaed",
		"icon-meh-o": "\eaee",
		"icon-meh": "\eaef",
		"icon-menu": "\eaf0",
		"icon-microphone-filled": "\eaf1",
		"icon-microphone-frontslash-filled": "\eaf2",
		"icon-microphone-slash-filled": "\eaf3",
		"icon-microphone-slash-solid": "\eaf4",
		"icon-microphone": "\eaf5",
		"icon-minus-circle-filled": "\eaf6",
		"icon-minus-circle-solid": "\eaf7",
		"icon-minus-square-l": "\eaf8",
		"icon-minus-square-o": "\eaf9",
		"icon-minus-square-solid": "\eafa",
		"icon-mobile-phone": "\eafb",
		"icon-mobile": "\eafc",
		"icon-model-accuracy": "\eafd",
		"icon-mouse-pointer": "\eafe",
		"icon-my-tasks": "\eaff",
		"icon-no-data": "\eb00",
		"icon-not-done": "\eb01",
		"icon-object-detection-project": "\eb02",
		"icon-object-ungroup": "\eb03",
		"icon-paint-brush": "\eb04",
		"icon-paper-plane": "\eb05",
		"icon-paperclip": "\eb06",
		"icon-part-templates": "\eb07",
		"icon-pause-circle-solid": "\eb08",
		"icon-pdf": "\eb09",
		"icon-pdf_questionnaire": "\eb0a",
		"icon-pdf_report": "\eb0b",
		"icon-pen": "\eb0c",
		"icon-pencil": "\eb0d",
		"icon-phone-alt-solid": "\eb0e",
		"icon-phone-slash-solid": "\eb0f",
		"icon-phone-solid": "\eb10",
		"icon-phone": "\eb11",
		"icon-play-circle-solid": "\eb12",
		"icon-play-circle": "\eb13",
		"icon-play": "\eb14",
		"icon-plug-solid": "\eb15",
		"icon-plus-circle-solid": "\eb16",
		"icon-plus-square-o": "\eb17",
		"icon-plus": "\eb18",
		"icon-print": "\eb19",
		"icon-project-classes": "\eb1a",
		"icon-project-images": "\eb1b",
		"icon-puzzle-piece": "\eb1c",
		"icon-qrcode": "\eb1d",
		"icon-question-circle": "\eb1e",
		"icon-question": "\eb1f",
		"icon-radio-false": "\eb20",
		"icon-radio-true": "\eb21",
		"icon-recording": "\eb22",
		"icon-redo": "\eb23",
		"icon-refresh": "\eb24",
		"icon-reorder-list": "\eb25",
		"icon-rotate-minus-ninety": "\eb26",
		"icon-rotate-ninety": "\eb27",
		"icon-save": "\eb28",
		"icon-scanner": "\eb29",
		"icon-screen-mirror": "\eb2a",
		"icon-screen-share": "\eb2b",
		"icon-search-minus": "\eb2c",
		"icon-search-plus": "\eb2d",
		"icon-search": "\eb2e",
		"icon-service-report": "\eb2f",
		"icon-settings": "\eb30",
		"icon-share-from-square": "\eb31",
		"icon-shield": "\eb32",
		"icon-show-grid": "\eb33",
		"icon-show-labels": "\eb34",
		"icon-show-to-audience": "\eb35",
		"icon-show-whiteboard": "\eb36",
		"icon-sign-detection": "\eb37",
		"icon-sign-in": "\eb38",
		"icon-sign-out": "\eb39",
		"icon-smartglass": "\eb3a",
		"icon-smartwatch": "\eb3b",
		"icon-sort-alpha-asc": "\eb3c",
		"icon-sort-alpha-desc": "\eb3d",
		"icon-spinner": "\eb3e",
		"icon-square-full-solid": "\eb3f",
		"icon-square-o": "\eb40",
		"icon-square-s": "\eb41",
		"icon-square": "\eb42",
		"icon-standard-template": "\eb43",
		"icon-step-forward": "\eb44",
		"icon-stop-circle-solid": "\eb45",
		"icon-stop-circle": "\eb46",
		"icon-stop-solid": "\eb47",
		"icon-stop": "\eb48",
		"icon-streams-mode-1": "\eb49",
		"icon-streams-mode-2": "\eb4a",
		"icon-sun": "\eb4b",
		"icon-sync": "\eb4c",
		"icon-t": "\eb4d",
		"icon-tablet": "\eb4e",
		"icon-tasks-solid": "\eb4f",
		"icon-tasks": "\eb50",
		"icon-terminal": "\eb51",
		"icon-text": "\eb52",
		"icon-th-large": "\eb53",
		"icon-th": "\eb54",
		"icon-thumbs-up": "\eb55",
		"icon-ticket-slash": "\eb56",
		"icon-times-circle-filled": "\eb57",
		"icon-times-circle": "\eb58",
		"icon-times": "\eb59",
		"icon-toggle-off": "\eb5a",
		"icon-toggle-on": "\eb5b",
		"icon-torch-slash": "\eb5c",
		"icon-torch": "\eb5d",
		"icon-tracking": "\eb5e",
		"icon-translation": "\eb5f",
		"icon-trash": "\eb60",
		"icon-triangle": "\eb61",
		"icon-truck": "\eb62",
		"icon-ubimax": "\eb63",
		"icon-undo": "\eb64",
		"icon-unlock": "\eb65",
		"icon-upload-solid": "\eb66",
		"icon-upload": "\eb67",
		"icon-user-plus": "\eb68",
		"icon-user-times": "\eb69",
		"icon-user": "\eb6a",
		"icon-users": "\eb6b",
		"icon-version": "\eb6c",
		"icon-video-filled": "\eb6d",
		"icon-video-frontslash-filled": "\eb6e",
		"icon-video-slash-filled": "\eb6f",
		"icon-video-slash-solid": "\eb70",
		"icon-video": "\eb71",
		"icon-warning": "\eb72",
		"icon-wifi": "\eb73",
		"icon-window-restore": "\eb74",
		"icon-workflow-layout": "\eb75",
		"icon-workflow": "\eb76",
		"icon-wpforms": "\eb77",
		"icon-xAssist": "\eb78",
		"icon-yt-fullscreen": "\eb79",
		"icon-yt-minscreen": "\eb7a",
		"icon_aistudio_logo_24": "\eb7b",
		"icon_fluent_board_24_filled": "\eb7c",
		"icon_fluent_camera_24_regular": "\eb7d",
		"icon_fluent_image_add_24_regular": "\eb7e",
		"icon_fluent_search_visual_24_regular": "\eb7f"
	)
));


$create-font-face: true !default; // should the @font-face tag get created?

// should there be a custom class for each icon? will be .filename
$create-icon-classes: true !default; 

// what is the common class name that icons share? in this case icons need to have .icon.filename in their classes
// this requires you to have 2 classes on each icon html element, but reduced redeclaration of the font family
// for each icon
$icon-common-class: 'icon' !default;

// if you whish to prefix your filenames, here you can do so.
// if this string stays empty, your classes will use the filename, for example
// an icon called star.svg will result in a class called .star
// if you use the prefix to be 'icon-' it would result in .icon-star
$icon-prefix: '' !default; 

// helper function to get the correct font group
@function iconfont-group($group: null) {
  @if (null == $group) {
    $group: nth(map-keys($__iconfont__data), 1);
  }
  @if (false == map-has-key($__iconfont__data, $group)) {
    @warn 'Undefined Iconfont Family!';
    @return ();
  }
  @return map-get($__iconfont__data, $group);
}

// helper function to get the correct icon of a group
@function iconfont-item($name) {
  $slash: str-index($name, '/');
  $group: null;
  @if ($slash) {
    $group: str-slice($name, 0, $slash - 1);
    $name: str-slice($name, $slash + 1);
  } @else {
    $group: nth(map-keys($__iconfont__data), 1);
  }
  $group: iconfont-group($group);
  @if (false == map-has-key($group, $name)) {
    @warn 'Undefined Iconfont Glyph!';
    @return '';
  }
  @return map-get($group, $name);
}

// complete mixing to include the icon
// usage:
// .my_icon{ @include iconfont('star') }
@mixin iconfont($icon) {
  $slash: str-index($icon, '/');
  $group: null;
  @if ($slash) {
    $group: str-slice($icon, 0, $slash - 1);
  } @else {
    $group: nth(map-keys($__iconfont__data), 1);
  }
  &:before {
    font-family: $group;
    font-style: normal;
    font-weight: 400;
    content: iconfont-item($icon);
  }
}

// creates the font face tag if the variable is set to true (default)
@if $create-font-face == true {
  @font-face {
   font-family: "iconfont";
   src: url('../font/iconfont.eot'); /* IE9 Compat Modes */
   src: url('../font/iconfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
      url('../font/iconfont.woff') format('woff'), /* Pretty Modern Browsers */
      url('../font/iconfont.ttf')  format('truetype'), /* Safari, Android, iOS */
      url('../font/iconfont.svg') format('svg'); /* Legacy iOS */
  }
}

// creates icon classes for each individual loaded svg (default)
@if $create-icon-classes == true {
  .#{$icon-common-class} {
    font-style: normal;
    font-weight: 400;

    @each $icon, $content in map-get($__iconfont__data, "iconfont") {
      &.#{$icon-prefix}#{$icon}:before {
        font-family: "iconfont";
        content: iconfont-item("iconfont/#{$icon}");
      }
    }
  }
}
