.sub-menu-bar
    -webkit-transition: all 0.4s ease
    transition: all 0.4s ease
    background-color: $body-bg-color
    margin-bottom:  $spacing-base


    &.sticky
        position: absolute
        top: 0
        background-color: white
        width: 100%
        height: 120px
        margin-left: -45px
        padding-left: $col-spacing
        box-shadow: 0 4px 2px -2px rgba(0,0,0,.08)
        z-index: 1

