.form-check, .form-radio
    input[type='checkbox'], input[type='radio']
      position: absolute
      opacity: 0

      & + label
        position: relative
        cursor: pointer
        padding: 0
        display: inline-flex

      & + label:after
        font-family: $icon-font-name

      & + label:before
        content: ''
        margin-right: 10px
        display: inline-block
        vertical-align: text-top
        width: 20px
        height: 20px
        background: #ddd
        @include themify($themes)
            border: 2px solid themed('mainColor')
            background-color: white

      &:hover + label:before
        @include themify($themes)
            background: themed('mainColor')

      &:focus + label:before
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12)

      &:checked + label:before
        @include themify($themes)
           background: themed('mainColor')

      &:checked
        &:disabled + label:before
          @include themify($themes)
            background: #ddd

      &:disabled + label
        color: #b8b8b8
        cursor: auto

      &:disabled + label:before
        box-shadow: none
        background: #ddd
        border-color: #ddd

      &:checked + label:after
        box-sizing: border-box
        transform: rotate(45deg)
        position: absolute
        left: 7px
        top: 3px
        display: table
        width: 7px
        height: 13px
        border-width: 2px
        border-style: solid
        border-top: 0
        border-left: 0
        content: ""
        color: $color-white

      &:disabled
          &:hover + label:before
            background: #ddd

.form-radio
    input[type='radio']
        & + label:before
            border-radius: 50%
        &:checked + label:after
            content: ''
            @include themify($themes)
               background: themed('mainColor')
            width: 10px
            height: 10px
            border-radius: 50%
            border: 2px solid white
            width: 16px
            height: 16px
            top: 2px
            left: 2px

