// TABLES
// ==============================

$table-border-color: $color-grey-200 !default
$table-header-text-color: $color-grey-500 !default

table 
    margin: 15px 0
    width: 100%
    border-spacing: 0
    border-collapse: collapse

    tr 
        border-bottom: 1px solid $table-border-color
        text-align: left
    

    thead 
        th 
            padding: 15px
            font-weight: 300
            color: $table-header-text-color
        
    

    tbody 
        td 
            padding: 3px
            font-weight: 300
        
    
    
    &.data-sheet
        tr
            border-bottom: none
            &:hover
                background-color: $color-grey-100
        tbody

            td
                padding: 0
                padding: 2px 0

    &.first-column-bold
        td
            &:first-child
                font-weight: 600
                padding-right: $spacing-base
                text-align: right
                vertical-align: top



