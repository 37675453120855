// SECTIONS
// ==============================

$section-primary-bg-color: $color-busy !default
$section-secondary-bg-color: $color-grey-100 !default
$section-tertiary-bg-color: $color-white !default

.section,
section 
    padding: 30px

    >:last-child 
        margin-bottom: 0


.section-primary 
    background: $section-primary-bg-color

.section-secondary
    background: $section-secondary-bg-color

.section-tertiary
    background: $section-tertiary-bg-color
