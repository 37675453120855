/**
  * ---
  *
  * title: Button Text Input Group
  * desc: 
  * showSideBySideWidth: col-sm-10
  *
  * htmlCodeVariations:
  * - title: Button Text Input Group
  *   code: |
  *     <div class="button-text-input-group">
  *          <button class="button button-primary">
  *             <i class="icon icon-reorder-list"></i> 
  *          </button>
  *          <input type="text" placeholder="Enter some info">
  *
  *          <button class="button button-primary">
  *              <i class="icon icon-pen"></i> 
  *          </button>
  *
  *          <button class="button button-primary">
  *              <i class="icon icon-trash"></i> 
  *          </button>
  *      </div>
  * - title: Button Dropdown Group
  *   code: |
  *     <div class="button-text-input-group">
  *          <button class="button button-primary">
  *              <i class="icon icon-reorder-list"></i> 
  *          </button>
  *
  *          <div class="btn-group" uib-dropdown>
  *              <button id="single-button" type="button" uib-dropdown-toggle ng-disabled="disabled">
  *                  <span class="btn-text">Button dropdown</span>
  *                  <span class="caret"></span>
  *              </button>
  *
  *              <ul class="dropdown-menu" uib-dropdown-menu role="menu" aria-labelledby="single-button">
  *                  <li role="menuitem"><a href="#">Action</a></li>
  *                  <li role="menuitem"><a href="#">Another action</a></li>
  *                  <li role="menuitem"><a href="#">Something else here</a></li>
  *                  <li class="divider"></li>
  *                  <li role="menuitem"><a href="#">Separated link</a></li>
  *              </ul>
  *
  *           </div>
  *
  *          <button class="button button-primary">
  *              <i class="icon icon-trash"></i>
  *          </button>
  *      </div>
  * - title: Button Text Input Group Small
  *   code: |
  *     <div class="button-text-input-group button-text-input-group-small">
  *          <button class="button button-primary">
  *             <i class="icon icon-reorder-list"></i> 
  *          </button>
  *          <input type="text" placeholder="Enter some info">
  *
  *          <button class="button button-primary">
  *              <i class="icon icon-pen"></i> 
  *          </button>
  *
  *          <button class="button button-primary">
  *              <i class="icon icon-trash"></i> 
  *          </button>
  *      </div>
  * - title: Button Dropdown Group Small
  *   code: |
  *     <div class="button-text-input-group button-text-input-group-small">
  *          <button class="button button-primary">
  *              <i class="icon icon-reorder-list"></i> 
  *          </button>
  *
  *          <div class="btn-group" uib-dropdown>
  *              <button id="single-button" type="button" uib-dropdown-toggle ng-disabled="disabled">
  *                  <span class="btn-text">Button dropdown</span>
  *                  <span class="caret"></span>
  *              </button>
  *
  *              <ul class="dropdown-menu" uib-dropdown-menu role="menu" aria-labelledby="single-button">
  *                  <li role="menuitem"><a href="#">Action</a></li>
  *                  <li role="menuitem"><a href="#">Another action</a></li>
  *                  <li role="menuitem"><a href="#">Something else here</a></li>
  *                  <li class="divider"></li>
  *                  <li role="menuitem"><a href="#">Separated link</a></li>
  *              </ul>
  *
  *           </div>
  *
  *          <button class="button button-primary">
  *              <i class="icon icon-trash"></i>
  *          </button>
  *      </div>
  * 
  *
  * fineprint:
  *   version: 
  *   update: 
  *   owner: Magdalena Riecken
  * ---
  */

.button-text-input-group
    display: flex
    margin: 7.5px 0

    &.button-text-input-group-small
        button, input, textarea
            height: 30px
            display: flex
            justify-content: center
            align-items: center
            padding-top: inherit
            align-content: center
            padding-bottom: inherit
            padding-left: 8px
            padding-right: 8px

            &.button-primary
                padding: 0 7.5px

        textarea
            padding-top: 4px
            padding-bottom: 4px

        .btn-group.dropdown
            button
                height: 28px
                span
                    line-height: initial

    > *
        height: auto

        @include themify($themes)
            border: 1px solid themed(mainColor)
            border-right: none

        button
            border: none !important
        &:hover
            background-color: #E3E3E3

        &.btn-group.dropdown
            display: flex
            align-items: center
            // overflow: hidden
            max-width: 100%

            button
                border: none !important
                text-transform: none
                width: 100%
                text-align: left
                display: flex
                align-items: center

                .btn-text
                    flex-grow: 2
                    text-align: left
                    white-space: nowrap
                    overflow: hidden
                    text-overflow: ellipsis

            ul.dropdown-menu
                width: 100%

                p.group-label
                    padding: 0 10px
                    margin: 8px 0 0 0
                li
                    a
                        text-overflow: ellipsis
                        overflow: hidden
                li:hover
                    @include themify($themes)
                        background-color: rgba(themed(mainColor), .2)
                a:hover
                    opacity: 1




    > :first-child
        // border-radius: 5px  0px 0px 5px

    > :last-child
        // border-radius: 0px  5px 5px 0px
        @include themify($themes)
            border-right: 1px solid themed(mainColor)

    .btn-group
        flex: 2

        button
            letter-spacing: 0
    button
        margin: 0

        &.button-primary
            line-height: 20px
            padding: 10px
            // font-size: 16px
            margin: 0
    input
        -webkit-appearance: none
        -moz-appearance: none
        appearance: none
        display: block
        padding: 0 10px
        // line-height: 20px
        // font-size: 16px
        font-weight: inherit
        background: transparent
        @include themify($themes)
            border: 1px solid themed(mainColor)
            border-right: none

    .group-label
        display: block
        font-weight: bold
        margin: 2px 5px
        cursor: default