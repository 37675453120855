/* You can add global styles to this file, and also import other style files */

@import "bootstrap/scss/bootstrap"
@import "assets/scss/mustard-ui"
//@import '@ubimax/frontend-resources/assets/scss/mustard-ui.sass'
@import "assets/scss/vars/vars"
@import 'assets/scss/mixins/base-mixins'
@import 'assets/scss/mixins/themify'
@import "@ubimax/frontend-resources/assets/theme/colors.css"
@import "ag-grid-community/dist/styles/ag-grid.css"
@import "ag-grid-community/dist/styles/ag-theme-alpine.css"

@import "@ubimax/frontend-resources/assets/scss/elements/angular2-multiselect-dropdown-theme.scss"
//@import "assets/scss/elements/angular2-multiselect-dropdown-theme.scss"
@import "animate.css"
@import '@angular/cdk/overlay-prebuilt.css'
@import "@ng-select/ng-select/themes/material.theme.css"
@import "ngx-toastr/toastr.css"
@import "flatpickr/dist/flatpickr.css"

.header
    height: 50px

main
    padding-top: 50px

.tooltip
    opacity: 1 !important

button
    .icon
        font-size: 18px

ngb-modal-backdrop
    z-index: 1050 !important
