// MENUS
// ==============================

$menu-shadow: true !default
$menu-bg-color: $color-white !default
$menu-hover-color: $color-grey-50 !default

.menu 
    display: inline-block
    list-style: none
    background: $menu-bg-color
    border-radius: 5px

    @if $menu-shadow 
        box-shadow: 0 4px 12px rgba($color-grey-500, 0.5)
    

    li 
        a 
            display: block
            padding: 10px 30px
            border-bottom: 1px solid $color-grey-200

            &:focus,
            &:hover 
                background: $menu-hover-color
            
        
    

