// CODE
// ==============================

$code-bg-color: $color-grey-100 !default
$code-border-color: $brand-color !default

pre 
    display: block
    margin: 15px 0
    padding: 10px 15px
    border-left: 4px solid $code-border-color
    background: $code-bg-color
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace
    font-size: 13px
    color: $color-grey-900
    white-space: normal
    // overflow-x: auto


code 
    background: $code-bg-color
    color: $color-grey-900
    font-family: Monaco, 'Courier New', Courier, monospace
    white-space: pre

