/* -----------------------------------------------
 * angular-lightbox
 * -----------------------------------------------
 */

.angular-lightbox-overlay
    position: absolute
    color: $color-white
    position: fixed
    width: 100%
    top: 0
    bottom: 0
    left: 0
    z-index: 1050
    background-color: rgba(0, 0, 0, .8)
    display: flex
    align-items: center
    justify-content: center

    &.single-image
        .angular-lightbox-inner
            .previous, .next
                display: none

    .angular-lightbox-inner
        width: 100vw
        height: 100vh
        display: flex
        flex-direction: row
        justify-content: center
        align-items: center

        .previous, .next
            flex: 0 0 auto
            width: 5vw
            display: flex
            justify-content: center
            align-items: center
            height: 30%
            cursor: pointer

            &:hover
                opacity: .7

        .img-wrapper
            max-width: 90vw
            max-height: 90vh
            flex: 1
            display: flex
            justify-content: center
            animation: .5s ease-in-out fadeInScale

        img
            animation: .3s ease-in-out fadeIn
            max-height: 100%
            object-fit: contain

    .close
        position: absolute
        top: 3 * $spacing-base
        right: 3 * $spacing-base
        cursor: pointer

        &:hover
            opacity: .7

@keyframes fadeInScale
    0%
        transform: scale(0.6)
        opacity: 0
    100%
        transform: scale(1)
        opacity: 1

@keyframes fadeIn
    0%
        opacity: 0
    100%
        opacity: 1
