// FORMS
// ==============================
$animation-duration: 0.25s

$input-bg-color: $color-white !default
$input-active-color: $brand-color !default
$input-border-color: $color-grey-100 !default
$input-invalid-color: $color-offline !default
$input-error-text-color: $color-close !default
$input-warning-text-color: $color-busy !default
$label-color: $color-grey-900 !default


@mixin label-active()
	font-size: $font-size-base * 0.8
	font-weight: 900
	transform: translateY(-26px)
	padding: 12px 0 0


/* General styling for input-like fields
input[type="text"],
input[type="number"],
input[type="password"],
select,
textarea
	appearance: none
	display: block
	width: 100%
	font-weight: 400

	&:disabled
		opacity: 0.5
		cursor: not-allowed

	&:focus
		outline: none
		@include themify($themes)
			border-color: themed('mainColor')

	&.invalid
		border-color: $input-error-text-color


/* Select specific styling */
select
	padding-right: 30px
	-moz-appearance: none
	-webkit-appearance: none
	appearance: none
	cursor: pointer

	&:hover
		cursor: pointer


/* Textarea specific styling
textarea
	padding: 10px 15px
	// min-height: 100px
	font-size: 16px
	font-weight: 300
	color: $color-black
	background-color: $input-bg-color
	border: 1px solid $input-border-color

	&:focus
		@include themify($themes)
			border-color: themed('mainColor')
		outline: none

	&.invalid
		border-color: $input-invalid-color

/* Input specific styling
//Hide Arrows in input type number
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
	-webkit-appearance: none
	margin: 0

/* Firefox */
//disable edge password reveal eye to avoid duplicate icons
input::-ms-reveal, input::-ms-clear
	display: none

input
	font-size: $font-size-base

/* Fieldset styling
fieldset
	margin: 30px 0 0 0
	padding: 0
	border: none

/* Legend styling
legend
	padding: 0
	font-size: $font-size-base * 0.8
	margin-bottom: 8px
	color: $label-color
	font-weight: $font-weight-bold

/* Label styling
label
	font-size: $font-size-base
	font-weight: $font-weight


/* Component specific styling:
form-field-text-input
	width: 100%
	display: block

form
	.form-field
		margin-bottom: $spacing-base

.hint
	color: $color-grey-500
	font-size: $font-size-small


.form-field
	overflow: hidden

	&.form-field-typeahead
		overflow: initial

	&.multi-select, &.multi-select-tags
		overflow: unset

	&.multi-select-tags
		.form-field-input
			height: auto

			ul.tags
				padding: 10px 0

	&.form-field-is-disabled
		label
			opacity: .5

	&.form-field-is-filled,
	&.form-field-is-autofilled,
	&.select
		.form-field-label
			@include label-active()
			color: $label-color


	&.form-field-is-active, &.open
		.form-field-control
			&::after
				@include themify($themes)
					border-bottom: 2px solid themed('mainColor')
				width: 100%
				transform: scaleX(1)

		.form-field-label
			@include label-active()
			@include themify($themes)
				color: themed('mainColor')

	&.form-field.form-field-has-error
		.form-field-control
			&::after
				border-bottom: 2px solid $input-error-text-color
				width: 100%
				transform: scaleX(1)


	&.select
		position: relative

		&:after
			position: absolute
			right: 0
			top: 20px
			content: '\ea02'
			font-family: 'iconfont'
			z-index: 1
			pointer-events: none


.form-field-control
	//overflow: hidden
	position: relative
	width: 100%

	&::after
		content: ""
		display: block
		position: absolute
		bottom: 0
		left: 0
		right: 0
		margin: 0 auto
		width: 1%
		transform: scaleX(0)
		transition: all $animation-duration
		@include themify($themes)
			border-bottom: 2px solid themed('mainColor')


	.form-field-label
		position: absolute
		top: 0
		left: 0
		transition: all $animation-duration
		//z-index: -1
		cursor: text
		font-size: inherit
		font-weight: inherit


.form-field-label, .label
	display: inline-block
	font-weight: $font-weight
	margin: 0
	width: 100%
	text-overflow: ellipsis
	white-space: nowrap
	overflow: hidden
	color: #454545
	@include label-active()
	transform: none
	padding: 5px 0px 0

.form-field-input,
.form-field-textarea,
.form-field-select,
.searchField
	appearance: none
	background: transparent
	border: 0
	border-bottom: 1px solid $color-grey-300
	color: $font-color
	display: block
	margin-top: $spacing-base
	outline: 0
	width: 100%
	height: 2rem

	+ .input-field-icon
		float: right
		margin-left: -25px
		margin-top: -25px
		position: relative
		z-index: 2
		color: $font-color

.form-field-is-autofilled
	.form-field-label
		transition: all 0s !important

//DISABLED MODE ==> SHOW DATA OVERVIEW

fieldset[disabled]
	legend
		color: $font-color
		font-weight: 900

	.form-field
		overflow: hidden

	.form-field-control
		border-color: transparent

		&::after
			border-bottom: 2px solid red
			transform: scaleX(0)
			transition: all $animation-duration

	.form-field-label
		transform: translateY(-26px)
		font-size: 0.8 * $font-size-base
		font-weight: 900
		padding-top: 25px


		&:after
			content: '-'
			position: absolute
			display: block
			left: 0
			top: 45px
			font-size: 14px
			font-weight: 300

	textarea,
	.form-field-input,
	.form-field-textarea,
	.form-field-select
		border-color: transparent
		cursor: default
		opacity: 1

	.select
		&:after
			display: none

	.form-field-is-filled,
	.form-field-is-autofilled,
	.select
		.form-field-label
			color: $font-color

			&:after
				display: none

	.dropdown-toggle
		opacity: 1
		cursor: auto

		&:hover
			background-color: transparent

	&:not([disabled])
		input::placeholder
			display: none
			color: transparent

.form-toggle
	.form-field-input
		border: none

	&.side-by-side
		.form-field-control
			display: flex
			flex-direction: row-reverse
			justify-content: flex-end
			align-items: center

			label.form-field-label
				transform: none
				width: auto
				padding: 0
				position: initial

			.form-field-input
				width: auto
				margin: 0
				display: flex
				align-items: center
				margin-right: 15px

				.switch
					margin: 0

// ==========VALIDATION ERROR AND WARNINGS ====================
.validation-error
	margin-top: 2px
	font-size: 12px
	color: $input-error-text-color

.form-field.form-field-has-error
	.form-field-control
		&::after
			border-bottom: 2px solid $input-error-text-color
			width: 100%
			transform: scaleX(1)

.input-one-digit input
	font-size: 2em
	@include themify($themes)
		color: themed('mainColor')
	text-align: center


// ================== Text Areas ===================
textarea.form-field-input
	font-family: $font-family
	font-weight: 400
	font-size: $font-size-base
	line-height: $line-height
	padding: 5px 0
	height: 2rem

