.ui-view
    height: calc(100vh - 50px)
    overflow-y: hidden
    overflow-x: hidden
    width: 100%

.ui-view-content
    display: flex
    flex-direction: column
    height: 100%
    //overflow-y: auto

    ::-webkit-scrollbar
        width: 0px

    .page-header
        z-index: 10
        padding: $spacing-base*2 0 0 $spacing-base
        background-color: $color-grey-10
        transition: padding-top .3s

        .sub-menu-bar
            .search-input
                margin-top: -1em  // For alignment purposes
            .filter-options-button
                padding-left: 0

        &.sticky-sub-menu
            position: sticky
            top: - $spacing-base * 5

        &.sticky-grid-menu
            padding-top: 0
            transition: padding-top .3s

    .page-content
        padding: $spacing-base*3 $spacing-base 0 $spacing-base*2
        transition: padding .3s
        height: 100%

        .fixed-table-header
            position: fixed
            top: $spacing-base * 8
            z-index: 1
            width: auto
            background-color: $color-grey-10

        &.compact-view
            padding: 0 0 0 $spacing-base*2 !important
            transition: padding .3s


        &.invisible
            position: absolute
            height: 0
            top: 0

.sticky-header
    .ag-header-row
        position: fixed
        top: unset !important
        background-color: $body-bg-color
        z-index: 2
