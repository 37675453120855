
//
// Tooltips
// --------------------------------------------------

$zindex-tooltip:           1070 !default
$font-size-small: 12px !default
$border-radius-base:        4px !default

$tooltip-max-width:           200px !default
$tooltip-color:               #fff !default
$tooltip-bg:                  #000 !default
$tooltip-opacity:             .9 !default
$tooltip-arrow-width:         5px !default
$tooltip-arrow-color:         $tooltip-bg !default

.tooltip 
  position: absolute
  z-index: $zindex-tooltip
  display: block
  //@include reset-text
  font-size: $font-size-small
  opacity: 0

  &.in
      opacity: .9
  &.top 
    padding: $tooltip-arrow-width 0
    margin-top: -3px
  
  &.right 
    padding: 0 $tooltip-arrow-width
    margin-left: 3px
  
  &.bottom 
    padding: $tooltip-arrow-width 0
    margin-top: 3px
  
  &.left 
    padding: 0 $tooltip-arrow-width
    margin-left: -3px
  

  // Note: Deprecated .top-left, .top-right, .bottom-left, and .bottom-right as of v3.3.1
  &.top .tooltip-arrow 
    bottom: 0
    left: 50%
    margin-left: -$tooltip-arrow-width
    border-width: $tooltip-arrow-width $tooltip-arrow-width 0
    border-top-color: $tooltip-arrow-color
  
  &.top-left .tooltip-arrow 
    right: $tooltip-arrow-width
    bottom: 0
    margin-bottom: -$tooltip-arrow-width
    border-width: $tooltip-arrow-width $tooltip-arrow-width 0
    border-top-color: $tooltip-arrow-color
  
  &.top-right .tooltip-arrow 
    bottom: 0
    left: $tooltip-arrow-width
    margin-bottom: -$tooltip-arrow-width
    border-width: $tooltip-arrow-width $tooltip-arrow-width 0
    border-top-color: $tooltip-arrow-color
  
  &.right .tooltip-arrow 
    top: 50%
    left: 0
    margin-top: -$tooltip-arrow-width
    border-width: $tooltip-arrow-width $tooltip-arrow-width $tooltip-arrow-width 0
    border-right-color: $tooltip-arrow-color
  
  &.left .tooltip-arrow 
    top: 50%
    right: 0
    margin-top: -$tooltip-arrow-width
    border-width: $tooltip-arrow-width 0 $tooltip-arrow-width $tooltip-arrow-width
    border-left-color: $tooltip-arrow-color
  
  &.bottom .tooltip-arrow 
    top: 0
    left: 50%
    margin-left: -$tooltip-arrow-width
    border-width: 0 $tooltip-arrow-width $tooltip-arrow-width
    border-bottom-color: $tooltip-arrow-color
  
  &.bottom-left .tooltip-arrow 
    top: 0
    right: $tooltip-arrow-width
    margin-top: -$tooltip-arrow-width
    border-width: 0 $tooltip-arrow-width $tooltip-arrow-width
    border-bottom-color: $tooltip-arrow-color
  
  &.bottom-right .tooltip-arrow 
    top: 0
    left: $tooltip-arrow-width
    margin-top: -$tooltip-arrow-width
    border-width: 0 $tooltip-arrow-width $tooltip-arrow-width
    border-bottom-color: $tooltip-arrow-color
  


// Wrapper for the tooltip content
.tooltip-inner 
  max-width: $tooltip-max-width
  padding: 3px 8px
  color: $tooltip-color
  text-align: center
  background-color: $tooltip-bg
  border-radius: $border-radius-base


// Arrows
.tooltip-arrow 
  position: absolute
  width: 0
  height: 0
  border-color: transparent
  border-style: solid

// TOOLTIPS
// ==============================

// $tooltip-bg-color: $color-grey-800 !default
// $tooltip-underline-color: $color-grey-400 !default

// .tooltip 
//     display: inline-block
//     position: relative
//     border-bottom: 1px dashed $tooltip-underline-color

//     &:focus,
//     &:hover 
//         cursor: pointer

//         .tooltip-text 
//             display: block
        
    


// .tooltip-text 
//     display: none
//     position: absolute
//     bottom: 125%
//     left: 50%
//     transform: translateX(-50%)
//     min-width: 300px
//     padding: 10px 15px
//     color: $color-white
//     text-align: center
//     background: $tooltip-bg-color
//     border-radius: 5px
//     box-shadow: 0 2px 4px $color-grey-500

//     &::after 
//         position: absolute
//         content: ''
//         top: 100%
//         left: 50%
//         transform: translateX(-50%)
//         border-width: 8px
//         border-style: solid
//         border-color: $tooltip-bg-color transparent transparent
    

