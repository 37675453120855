// BASE
// ==============================

$body-bg-color: $color-grey-10 !default
$brand-color: $color-available !default

*
    margin: 0
    padding: 0


html
    box-sizing: border-box


*, *::after, *::before
    box-sizing: inherit


body
    display: flex
    flex-direction: column
    min-height: 100vh
    background-color: $body-bg-color
    overflow: hidden



main
    flex: 1
    display: flex
    //padding-top: 50px remove for embedded app, adds extra padding
    min-height: 100%
    height: 100vh
    z-index: 0


.hide
    display: none !important

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak
  display: none !important
