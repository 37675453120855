// CONTAINERS
// ==============================

$container-sizes: ('small' : 768px, 'large' : 1440px) !default

.container 
    max-width: 1200px
    margin-left: auto
    margin-right: auto
    padding: 30px 0

.container-fluid 
    width: 100%
    padding-right: 15px
    padding-left: 15px
    margin-right: auto
    margin-left: auto

@each $name, $size in $container-sizes 
    .container-#{$name} 
        max-width: $size
    