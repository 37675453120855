@import '../../../assets/scss/vars/vars.sass'
@import '../../../assets/scss/mixins/themify.sass'

/**
  * ---
  *
  * title: Highlighting Overlay
  * desc:
  *
  * htmlCodeVariations:
  * - title: Full Screen Highlighting Overlay
  *   code: <button-primary id="button-to-be-highlighted" type="submit" text="Highlighting Button" ng-click="highlightExampleButton()"/>
  *   doNotPrintCodeExample: true
  *
  *
  * jsCodeVariations:
  * - title: Full Screen Highlighting Overlay
  *   code: |
  *             function UsersController(...,  highlightingService, ...) {  // Inject service into controller
  *                 // Click on "show me"-Button will highlight the Button which should be highlighted
  *                 highlightingService.show('#button-to-be-highlighted' )               // Will highlight this button
  *
  *                 highlightingService.hide()
  *
  *
  *             }
  *
  *
  * usage:
  *  - title: When to use
  *    description:
  *
  * attributes:
  * - attributeName:
  *   type:
  *   required:
  *   description:
  *   default:
  *
  * ---
  */

.highlighter
    content: ''
    height: 100%
    background-color: black
    width: 100%
    position: fixed
    z-index: 8
    display: block
    opacity: 0.8
    top: 0
    left: 0



.inForeground
    position: relative
    z-index: 9
    background-color: white