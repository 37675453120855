@import '/src/assets/scss/generated_iconfont'

.icon
    line-height: 1
    font-family: iconfont
    vertical-align: middle
    display: inline-block
    font-display: swap
    transform: translateY(-1px)

.icon-1-5-x
    font-size: 1.5em
.icon-2x
    font-size: 2em
.icon-3x
    font-size: 3em
.icon-4x
    font-size: 4em
.icon-5x
    font-size: 5em

.icon-spin
     animation: spin 4s linear infinite

@keyframes spin
    100%
        transform: rotate(360deg)

