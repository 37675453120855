
/**
  * ---
  *
  * title: Image Wrapper
  * desc: The image wrapper should be used in image grids in case the images' aspect ratios can be be diffrent. This will help to create an even grid. If not all image information are needed to be see
  * showSideBySideWidth: col-sm-4
  * htmlCodeVariations:
  * - title: aspect-ratio-1-1 image-contain
  *   code: |
  *            <p> Aspect Ratio 1:1 image-cover </p>
  *            <div class="image-wrapper aspect-ratio-1-1 image-contain">
  *              <div class="image ">
  *                <img src="src/assets/images/placeholder/placeholder01.png">
  *              </div>
  *            </div>
  * - title: aspect-ratio-16-9 image-contain
  *   code: |
  *            <p>  Aspect Ratio 16:9 image-cover </p>
  *            <div class="image-wrapper aspect-ratio-16-9 image-contain">
  *              <div class="image ">
  *                <img src="src/assets/images/placeholder/placeholder01.png">
  *              </div>
  *            </div>
  * - title: aspect-ratio-4-3 image-contain
  *   code: |
  *            <p> Aspect Ratio 4:3 image-cover </p>
  *            <div class="image-wrapper aspect-ratio-4-3 image-contain">
  *              <div class="image ">
  *                <img src="src/assets/images/placeholder/placeholder01.png">
  *              </div>
  *            </div>
  * - title: aspect-ratio-1-1 image-cover
  *   code: |
  *            <p>  Aspect Ratio 1:1 image-cover </p>
  *            <div class="image-wrapper aspect-ratio-1-1 image-cover">
  *              <div class="image ">
  *                <img src="src/assets/images/placeholder/placeholder01.png">
  *              </div>
  *            </div>
  * - title: aspect-ratio-16-9 image-cover
  *   code: |
  *            <p>  Aspect Ratio 16:9 image-cover </p>
  *            <div class="image-wrapper aspect-ratio-16-9 image-cover">
  *              <div class="image ">
  *                <img src="src/assets/images/placeholder/placeholder01.png">
  *              </div>
  *            </div>
  * - title: aspect-ratio-4-3 image-cover
  *   code: |
  *            <p>  Aspect Ratio 4:3 image-cover </p>
  *            <div class="image-wrapper aspect-ratio-4-3 image-cover">
  *              <div class="image ">
  *                <img src="src/assets/images/placeholder/placeholder01.png">
  *              </div>
  *            </div>
  * 
  * usage:
  *  - title: When to use
  *    description:
  * 
  * attributes:
  * - attributeName: 
  *   type: 
  *   required:
  *   description: 
  *   default: 
  *  
  * classes:
  * - className: image-wrapper
  *   required : yes
  *   description: 
  * - className: aspect-ratio-1-1
  *   required : 
  *   description: 
  * - className: aspect-ratio-16-9
  *   required : 
  *   description: 
  * - className: aspect-ratio-4-3
  *   required : 
  *   description: 
  * - className: aspect-ratio-3-2
  *   required : 
  *   description: 
  * - className: aspect-ratio-8-5
  *   required : 
  *   description: 
  * - className: image-contain
  *   required : 
  *   description: 
  * - className: image-cover
  *   required : 
  *   description: 

  *
  * fineprint:
  *   version: 
  *   update: 
  *   owner: Magdalena Riecken
  * ---
  */
.image-wrapper
    width: 100%
    padding-top: 100%
    position: relative

    >.image
        position: absolute
        top: 0
        left: 0
        bottom: 0
        right: 0

        >img, video
            object-fit: contain
            width: 100%
            height: 100%

    &.aspect-ratio-1-1
        padding-top: 100%
    &.aspect-ratio-16-9
        padding-top:  56.25%
    &.aspect-ratio-4-3
        padding-top: 75%
    &.aspect-ratio-3-2
        padding-top: 66.66%
    &.aspect-ratio-8-5
        padding-top: 62.5%

    &.image-contain
        img
            object-fit: contain

    &.image-cover
        img
            object-fit: cover
        
.image-click
    cursor: pointer