// GRID
// ==============================

$spacing-base: 15px !default
$col-count: 12 !default
$col-spacing: 30px !default
$row-spacing: 30px !default

.row
    display: flex
    flex-direction: row
    flex-wrap: wrap
    //margin-top: $spacing-base
    //margin-bottom: $spacing-base
    margin-right: -$spacing-base
    margin-left: -$spacing-base


    &.row-reverse
        flex-direction: row-reverse


    .col
        flex-grow: 1
        flex-basis: 100%
        max-width: 100%
        padding: 0 calc($col-spacing/2)
        transition: all 400ms ease

        &.col-reverse
            flex-direction: column-reverse

    @for $i from 0 through $col-count
        .col-xs-#{$i}
            flex-basis: calc($i / $col-count) * 100%
            max-width: calc($i / $col-count) * 100%

    @for $i from 0 through $col-count
        .col-xs-offset-#{$i}
            margin-left: calc($i / $col-count) * 100%


    @include breakpoint-min($bp-small)
        @for $i from 0 through $col-count
            .col-sm-#{$i}
                flex-basis: calc($i / $col-count) * 100%
                max-width: calc($i / $col-count) * 100%

        @for $i from 0 through $col-count
            .col-sm-offset-#{$i}
                margin-left: calc($i / $col-count) * 100%


    @include breakpoint-min($bp-medium)
        @for $i from 0 through $col-count
            .col-md-#{$i}
                flex-basis: calc($i / $col-count) * 100%
                max-width: calc($i / $col-count) * 100%

        @for $i from 0 through $col-count
            .col-md-offset-#{$i}
                margin-left: calc($i / $col-count) * 100%

    @include breakpoint-min($bp-large)
        @for $i from 0 through $col-count
            .col-lg-#{$i}
                flex-basis: calc($i / $col-count) * 100%
                max-width: calc($i / $col-count) * 100%

        @for $i from 0 through $col-count
            .col-lg-offset-#{$i}
                margin-left: calc($i / $col-count) * 100%

    @include breakpoint-min($bp-xlarge)
        @for $i from 0 through $col-count
            .col-xlg-#{$i}
                flex-basis: calc($i / $col-count) * 100%
                max-width: calc($i / $col-count) * 100%

        @for $i from 0 through $col-count
            .col-xlg-offset-#{$i}
                margin-left: calc($i / $col-count) * 100%

    .col-0
        width: 0
        flex-shrink: 1
        transition: all 400ms ease
        flex-grow: 0
        flex-basis: 0
        padding: 0
        overflow: hidden


