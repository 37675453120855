.dropzone
    display: none
    
    
    @include themify($themes)
        background-color: rgba(themed(mainColor), .9)
    z-index: 1
    position: absolute
    top: 0
    bottom: 0
    right: 0
    left: 0
    .dropzone-inner
        display: flex
        text-align: center
        flex-direction: column
        align-items: center
        justify-content: center
        border: 6px dashed $color-white
        margin: 30px
        flex-grow: 1
        position: relative

        .cloud-upload-logo
            font-size: 12em
            height: 20%
            width: 100%
            position: relative
            i
                position: absolute
                left: 50%
                top: 50%
                transform: translate(-50%, -50%)
                color: $color-white

                &.icon-file-upload-arrow
                    animation: jump 1s infinite
        
        h1, p
            color: $color-white

.nv-file-over
    .dropzone
        display: flex

@keyframes jump 
    0% 
        top: 50%
    50% 
        top: 40%
    100%
        top: 50%

