@import '../../../assets/scss/vars/vars.sass'


.callout-anchor
	position: relative

.callout-container
	right: 0
	box-shadow: rgba(0, 0, 0, 0.4) 0 0 5px 0
	background-color: $color-white
	position: absolute
	border-width: 1px
	border-style: solid
	border-color: rgb(232, 232, 232)
	outline: transparent
	z-index: 100

	&.medium
		width: 250px

	ul
		margin-bottom: 0
		letter-spacing: 0

	li
		display: list-item
		list-style: none

		a
			font-size: 14px
			font-weight: 400
			width: 100%
			height: calc(2 * $spacing-base)
			line-height: calc(2 * $spacing-base)
			display: block
			cursor: pointer
			padding-top: 0
			padding-right: calc($spacing-base / 2)
			padding-bottom: 0
			padding-left: 5px
			text-align: left

			&:hover
				@include themify($themes)
					background-color: transparentize(themed(mainColor), 0.8)

		&.headline
			font-weight: 500
			font-size: 12px
			padding-left: $spacing-base
			padding-top: $spacing-base

.button-dropdown
	display: inline-block
