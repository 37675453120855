$shade-10: $color-grey-800 !default
$shade-1: $color-grey-300 !default
$shade-0: $color-white !default

// Range Slider
$range-handle-color: $color-white !default
$range-handle-size: 12px !default

$range-track-color: $shade-1 !default
$range-track-height: 6px !default

.range-slider-wrapper
    display: flex
    align-items: center

    .range-slider-icon-left, .range-slider-icon-right
        display: flex
        color: $shade-1
        
    .range-slider
        width: 100%
        margin: 0 15px
        display: flex

    .range-slider-range
        -webkit-appearance: none
        width: 100%
        height: $range-track-height
        border-radius: 3px
        @include themify($themes) 
            background: themed('gradientEnd') /* Old browsers */
            background: -moz-linear-gradient(left,  themed('gradientEnd') 0%, themed('gradientStart') 100%)
            background: -webkit-gradient(linear, left, right, color-stop(0%,themed('gradientEnd')), color-stop(100%,themed('gradientStart'))) 
            background: -webkit-linear-gradient(left,  themed('gradientEnd') 0%,themed('gradientStart') 100%)
            background: -o-linear-gradient(left,  themed('gradientEnd') 0%,themed('gradientStart') 100%)
            background: -ms-linear-gradient(left,  themed('gradientEnd') 0%,themed('gradientStart') 100%)
            background: linear-gradient(to right,  themed('gradientEnd') 0%,themed('gradientStart') 100%)
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 )
      
        outline: none
        padding: 0
        margin: 0

        // Range Handle
        &::-webkit-slider-thumb 
            appearance: none
            width: $range-handle-size
            height: $range-handle-size
            background: $range-handle-color
            cursor: pointer
            transition: background .15s ease-in-out
            border-radius: 50%
            box-shadow: 0 0 0 2px $color-white, 0 0 0 4px $color-grey-500, 0 0 0 7px $color-white


            &:hover
                @include themify($themes)
                    background: themed(mainColor)

        &:active::-webkit-slider-thumb 
            @include themify($themes)
                background: themed(mainColor)
    
        &::-moz-range-thumb 
            width: $range-handle-size
            height: $range-handle-size
            border: 0
            background: $range-handle-color
            cursor: pointer
            transition: background .15s ease-in-out

    
        // Focus state
        &:focus 
        
            &::-webkit-slider-thumb
                @include themify($themes)
                    box-shadow: 0 0 0 2px $shade-0, 0 0 0 4px themed(mainColor), 0 0 0 7px $color-white


// Firefox Overrides
::-moz-range-track 
    background: $range-track-color
    border: 0

input::-moz-focus-inner,
input::-moz-focus-outer  
    border: 0 
