// NAVIGATION
// ==============================

$nav-bg-color: $color-grey-700 !default
$nav-link-color: $color-grey-400 !default
$nav-link-active-color: $brand-color !default
$nav-menu-toggle-color: $color-grey-800 !default

nav, .nav
    height: 50px
    width: 100%
    background: $nav-bg-color
    display: flex
    align-items: center
    justify-content: space-between
    height: 100%
    margin: 0 auto
    color: $nav-link-color

    a
        display: block
        color: $nav-link-color

    .nav-logo
        display: flex
        align-items: center
        font-size: 18px
        line-height: 32px

    .nav-links
        display: none
        list-style: none
        margin: 0
        height: 100%
        margin-left: auto

        @include breakpoint-min($bp-medium)
            display: flex

        li
            padding: 0 30px
            height: 100%
        li:last-child
            padding: 0 0 0 30px
        a
            height: 100%

        a
            display: flex
            align-items: center


            &:active
                color: $nav-link-active-color


            &.active
                border-bottom: 4px solid $nav-link-active-color

.toggle-menu-button
    font-size: 1.8em
    font-weight: 400
    margin-right: 20px
    cursor: pointer

.toggle-menu-button-toggled
    @include themify($themes)
        color: themed('secondaryColor')

