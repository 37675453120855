/**
  * ---
  *
  * title: Modal
  * desc:
  *
  * htmlCodeVariations:
  * - title:
  *   code: <button-primary ng-click="openModal()" text="Open Modal" />
  * - title: example.modal.template.html
  *   code: |
  *          <div class="modal-head">
  *             <h2 class="modal-title"> Modal Title <a class="float-right" href="">
  *               <i class="icon icon-times" ng-click="$ctrl.cancel()"></i></a></h2>
  *                <h2 class="modal-title" ng-if="createMode">Create App<a class="float-right" href=""><i class="icon icon-times"
  *                              ng-click="$ctrl.cancel()"></i></a></h2>
  *              </div>
  *              <div class="modal-body">
  *                  <div class="button-bar">
  *                      <button-secondary ng-click="doSomething();" icon="beer" text="Do Something" />
  *                      <button-secondary ng-click="doSomethingElse();" icon="bicycle" text="Do Something else" />
  *                  </div>
  *              <p>Put Your Modal Content Here</p>
  *              </div>
  *              <div class="modal-footer">
  *                  <button class="button-secondary" ng-click="$ctrl.cancel()">Cancel</button>
  *                 <button class="button-primary float-right" ng-click="$ctrl.ok()">Primary Action</button>
  *              </div>
  *   doNotRenderCodeExample: true
  *
  * jsCodeVariations:
  * - title: example.modal.js
  *   code: |
  *           export default ngModule => {
  *
  *
  *            function ExampleModalController($rootScope, $scope, $dialogConfirm, $element) {
  *
  *              let $ctrl = this;
                 $ctrl.$onInit = async function () {
  *                  $element.addClass('modal-content');
  *
  *              };
  *
  *              $ctrl.ok = async function () {
  *                  console.log('Primary Action')
  *                  $ctrl.close({ $value: $scope.selectedAppConfig });
  *              };
  *
  *              $ctrl.cancel = function () {
  *                  $ctrl.dismiss({ $value: 'cancel' });
  *              };
  *
  *            }
  *
  *
  *            ngModule.component('exampleModalComponent', {
  *              template: require('./example.modal.html'),
  *              controller: ExampleModalController,
  *              bindings: {
  *                  modalInstance: '<',
  *                  resolve: '<',
  *                  close: '&',
  *                  dismiss: '&'
  *              }
  *            });
  *           };
  * - title: Insert in your View Controller
  *   code: |
  *             $scope.openModal = function (selectedAppConfig, createMode) {
  *                  let modalInstance = $uibModal.open({
  *                      animation: true,
  *                      component: 'exampleModalComponent',
  *                      size: 'col-4',
  *                      backdrop: 'static',
  *                      resolve: {
  *                          selectedAppConfig: selectedAppConfig,
  *                          createMode: createMode
  *                      }
  *                  });
  *
  *                  modalInstance.result.then(async function (selectedAppConfig) {
  *                          //yes
  *                      }, async function () {
  *                          //no
  *                  });
  *              };
  *
  * usage:
  *  - title: When to use
  *    description:
  *
  * attributes:
  * - attributeName:
  *   type:
  *   required:
  *   description:
  *   default:
  *
  * classes:
  * - className:
  *   required :
  *   description:
  *
  * fineprint:
  *   version:
  *   update:
  *   owner: Magdalena Riecken
  * ---
  */

// MODALS
// ==============================

$modal-shadow: true !default
$modal-bg-color: $color-white !default
$modal-mask-color: $color-black !default
$modal-content-bg-color: $color-grey-200 !default

ngb-modal-window.modal
    display: block

.modal-backdrop
    position: fixed
    top: 0
    right: 0
    bottom: 0
    left: 0
    z-index: 1040
    background-color: #000
    opacity: .5

.modal
    position: fixed
    top: 0
    right: 0
    bottom: 0
    left: 0
    z-index: 1050
    display: none
    overflow: hidden
    -webkit-overflow-scrolling: touch
    outline: 0


.modal-dialog
    position: fixed
    top: $nav-header-height
    right: -640px
    height: calc(100vh - #{$nav-header-height})
    width: 450px
    max-width: 100vw
    z-index: 1050
    display: flex
    flex-direction: column
    justify-content: space-between
    border-left: 5px solid
    padding: $spacing-base * 2 0 $spacing-base * 2 $spacing-base * 2
    background: $color-white
    overflow: hidden
    transition: all ease-in-out 0.4s
    margin: 0

    @include themify($themes)
        border-color: themed('mainColor')

    @if $modal-shadow
        box-shadow: 0 4px 12px rgba($color-grey-600, 0.5)


.modal-content
    height: 100%
    display: flex
    flex-direction: column
    position: relative
    border: none


.modal-head
    padding-right: $spacing-base * 2

    .close-button
        text-align: right
    a:hover
        cursor: pointer

.modal-body
    flex: 1
    overflow-x: hidden
    overflow-y: auto
    padding: 0 $spacing-base + $scroll-bar-width 2 * $spacing-base 0

.modal-footer
    margin-top: $spacing-base
    padding-right: $spacing-base * 2
    display: flex
    justify-content: space-between
    align-items: center
    border: none

    & > *
        margin-bottom: 0


.modal.fade.show
    .modal-dialog
        transition: all ease-in-out 0.4s
        right: 0
        @for $i from 1 through $col-count
            &.modal-col-#{$i}
                right: 0

.modal.fade
    .modal-dialog
        transition: all ease-in-out 0.4s
        @for $i from 1 through $col-count
            &.modal-col-#{$i}
                width: calc($i / $col-count) * 100%
                min-width: 450px
                right: -($i / $col-count) * 100%

.modal-confirm
    display: flex
    align-items: center
    justify-content: center
    text-align: center
    .modal-confirm-content
        width: 100%
        text-align: left

    .confirmation-message
        font-weight: 300


.modal-error, .modal-loading
    &.fade.in
        .modal-dialog
            transition: all ease-in-out 0.4s
            @for $i from 1 through $col-count
                &.modal-col-#{$i}
                    right: inherit
                    opacity: 1

    &.fade
        .modal-dialog
            transition: all ease-in-out 0.4s
            @for $i from 1 through $col-count
                &.modal-col-#{$i}
                    right: inherit
                    opacity: 0
    .modal-dialog
        height: 100vh
        background-color: rgba(0, 0, 0, .7)
        border-left: none
        top: 0
    .modal-content
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        height: 100%
        color: $color-white

        .modal-inner-content
            border-left: 5px solid #7b0505
            padding-left: $spacing-base

        h2
            color: $color-white


.modal-loading

    .frontline-loading-animation
        height: 50px
    .loading-message
        margin-top: 2* $spacing-base



