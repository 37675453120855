.toggle-switch
  .form-field-label
    cursor: pointer

  .icon-info
    display: inline-flex

  .switch
    background: #fff
    border: 1px solid #dfdfdf
    position: relative
    display: inline-block
    box-sizing: content-box
    overflow: visible
    width: 30px
    height: 15px
    padding: 0
    margin: 0
    margin-top: 10px
    border-radius: 20px
    cursor: pointer
    box-shadow: rgb(223, 223, 223) 0 0 0 0 inset
    transition: 0.3s ease-out all
    -webkit-transition: 0.3s ease-out all
    top: -1px
    .status-label
      position: absolute
      left: 40px
      font-size: 12px
      font-weight: 500

    &.wide
      width: 80px
    small
      background: #fff
      border-radius: 100%
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4)
      width: 15px
      height: 15px
      position: absolute
      top: 0
      left: 0
      transition: 0.3s ease-out all
      -webkit-transition: 0.3s ease-out all

    &.checked
      @include themify($themes)
        background: themed('mainColor')
        border-color: themed('mainColor')

      small
        left: 15px

    &.wide.checked small
      left: 52px
    .switch-text
      font-family: Arial, Helvetica, sans-serif
      font-size: 13px
    .off
      display: block
      position: absolute
      right: 10%
      top: 25%
      z-index: 0
      color: #A9A9A9
    .on
      display: none
      z-index: 0
      color: #fff
      position: absolute
      top: 25%
      left: 9%
    &.checked
      .off
        display: none
      .on
        display: block
    &.disabled
      opacity: .50
      cursor: not-allowed