// TAGS
// ==============================

$tag-text-color: $color-white !default
$tag-default-color: $color-grey-500 !default
$tag-colors: ('blue'    : $color-blue-500,'red'     : $color-offline,'green'   : $color-available,'orange'  : $color-busy,) !default

.tags
    display: flex
    flex-wrap: wrap
    list-style: none

.tag
    margin-right: 5px
    padding: 2px 6px
    font-size: 12px
    font-weight: 400
    border-radius: 4px
    color: $tag-text-color
    background: $tag-default-color
    box-shadow: 0 2px 4px $color-grey-200
    line-height: $line-height

    a
        display: inline-block
        color: $color-white

.tag-rounded
    border-radius: 16px

.tag-primary2
    @include themify($themes)
        background-color: themed(mainColor)

.tag-primary
    color: $color-black
    @include themify($themes)
        background-color: rgba(themed(mainColor),.1)

.tag-inline
    margin-right: 5px
    padding: 2px 6px
    font-size: 12px
    font-weight: 400
    border-radius: 4px
    color: $tag-default-color
    background: transparent
    box-shadow: none
    line-height: $line-height

@each $name, $color in $tag-colors
    .tag-#{$name}
        background: $color



