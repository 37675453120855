// TYPOGRAPHY
// ==============================

body
  font-family: $font-family
  font-size: $font-size-sm
  line-height: $line-height
  font-weight: $font-weight
  color: $font-color


h1,
h2,
h3,
h4,
h5,
h6
  font-family: $heading-font-family
  font-weight: $heading-font-weight
  @include themify($themes)
    color: themed(secondaryColor)
  margin: 0 0 10px


.h1,
h1
  font-size: $font-size-base * 3
  font-size: 36px
  line-height: $font-size-base * 3.75

  &.headline
    max-height: $font-size-base *4
    opacity: 1
    transition-property: max-height, opacity
    transition-duration: .3s
    transition-timing-function: linear, step-end

    &.invisible
        max-height: 0
        opacity: 0
        transition-property: max-height, opacity
        transition-duration: .3s
        transition-timing-function: linear, step-start


.h2,
h2
  // font-size: $font-size-base * 2.5
  font-size: 28px
  line-height: $font-size-base * 3.25


.h3,
h3
  font-size: $font-size-base * 2
  font-size: 18px
  line-height: $font-size-base * 2.75


.h4,
h4
  font-size: $font-size-base * 1.75
  line-height: $font-size-base * 2.5


.h5,
h5
  font-size: $font-size-base * 1.5
  line-height: $font-size-base * 2.25


.h6,
h6
  font-size: $font-size-base * 1.25
  line-height: $font-size-base * 2


p
  margin-bottom: 15px

  &.magnify
    font-size: $font-size-base * 1.5
    line-height: 1.8



strong,
b,
.bold
    font-weight: 600

.font-theme-color
  @include themify($themes)
    color: themed(secondaryColor)

