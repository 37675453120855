$font-size: $font-size-base
$foreground-color-opacity: 1
$foreground-color: $font-color
$grid-size: 8px
$header-foreground-color: $font-color
$header-height: $grid-size * 5
$icon-size: 30px
$row-height: $grid-size * 5
$selected-color: $color-grey-100
$virtual-item-height: 100px

// Import the ag-Grid material theme
@import 'ag-grid-community/src/styles/ag-grid.scss'
@import 'ag-grid-community/src/styles/ag-theme-material/sass/legacy/ag-theme-material-v22-compat.scss'

@mixin highlight
    @include animate(highlight, 2.5s, 1, normal)

.ag-theme-material
    --ag-background-color: $color-grey-10
    --ag-header-background-color: $color-grey-10
    --ag-font-size: $font-size-base

    .ag-cell
        display: flex
        align-items: center

    .ag-root
        min-height: 200px

        .rowUpdated
            @include highlight

    .ag-header-cell-label
        .ag-header-icon
            font-size: 18px
            @include themify($themes)
                color: themed('secondaryColor')


    .ag-row-selected
        &:before
            content: ' '
            @include themify($themes)
                background-color: themed('secondaryColor')

            height: calc(100% + 2px)
            display: inline-block
            width: 5px
            position: absolute
            top: -1px

    .ag-row
        &:hover
            @include themify($themes)
                background-color: themed('gradientEnd')

        &:hover .more
            display: inline-block

        .more
            display: none

    .icon-grid
        font-size: 18px

    .ag-checkbox-input-wrapper::after,
    .ag-checkbox-input-wrapper.ag-checked::after,
    .ag-checkbox-input-wrapper.ag-indeterminate::after
        @include themify($themes)
            color: themed(mainColor)

    @include keyframes(highlight)
        from
            color: $color-grey-500
            filter: grayscale(100%) opacity(10%)
        to
            background: none
            color: none
            filter: grayscale(0%) opacity(100%)

