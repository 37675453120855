// BUTTONS
// ==============================

$button-shadow: false !default
$button-text-color: $color-white !default
$button-colors: ( 'primary' : $brand-color, 'success' : $color-available, 'info': $color-grey-500, 'warning' : $color-busy, 'danger'  : $color-offline) !default

// In the file-input-component we want to hide the old input button
// CSS properties such as display: none or visibility: hidden will not work out.
// The reasons are: the input value will not be sent to the server on form
// submit the input will be excluded out of tab order (you want your website to
// be accessible, right?). We set up a combination of CSS properties/values for
// hiding the input visually but keeping it visible for the browser:
#file-upload
    height: 0.1px
    width: 0.1px
    opacity: 0
    z-index: -1

.button,
button,
input[type='button'],
input[type='reset'],
input[type='submit'],
.file-upload-button
    display: inline-block
    height: 40px
    margin: 0px 0px 15px 15px
    //margin: 0 0px 0px
    padding: 0 14px
    width: auto
    background: none
    overflow: visible
    appearance: none
    transition: all .1s ease-out
    font-size: 14px
    font-weight: 300
    line-height: 40px
    letter-spacing: 1px
    text-decoration: none
    text-transform: uppercase
    white-space: nowrap
    border-style: solid
    border-width: 1px
    letter-spacing: 0
    @include themify($themes)
        color: themed('secondaryColor')


    @if $button-shadow
        box-shadow: 0 2px 4px rgba($color-grey-500, 0.5)


    &:focus
        outline: none


    // &:focus,
    &:hover
        cursor: pointer
        background-color: $color-grey-200

        @if $button-shadow
            box-shadow: 0 2px 8px rgba($color-grey-600, 0.5)



    &:active
        opacity: 1


    &:disabled
        opacity: 0.5

        &:hover
            cursor: not-allowed




.button-large
    height: 48px
    line-height: 48px
    padding: 0 30px
    font-size: 16px


.button-small
    height: 30px
    line-height: 30px
    padding: 0
    font-size: 12px


.button-primary
    @include themify($themes)
        color: themed('secondaryColor')
        border-color: themed('secondaryColor')

    &.inverse
        @include themify($themes)
            background-color: themed('secondaryColor')
            border-color: $color-white
            color: $color-white

.button-secondary
    border-color: transparent
    text-transform: none
    @include themify($themes)
        color: themed('secondaryColor')
        padding-left: 0
        padding-right: 0
        margin: 0 calc($spacing-base/2)

    &:focus
       background-color: transparent

    &:hover, &:active, &active
       @include themify($themes)
           border-bottom: 1px solid themed('secondaryColor')
       background-color: transparent

.button-tertiary
    border-color: transparent
    text-transform: none
    border: none
    @include themify($themes)
        color: themed('secondaryColor')



.button-quaternary
    @include themify($themes)
        color: $color-white
        background-color: themed('secondaryColor')

    &:hover,  &:focus, &:active, &active
         @include themify($themes)
            background-color: darken(themed('secondaryColor'), 10%)

.button-bar, .sub-menu-bar
    .button-secondary:first-of-type
        margin-left: 0

.btn-group
    &.dropdown
        button:first-child
            margin-right: 0
