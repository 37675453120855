.empty-state
    // display: flex
    // justify-content: center
    // height: 100%
    // align-items: center
    text-align: center



    h2, p
        color: $color-grey-600
    
.empty-wrapper
    height: calc(100vh - 150px)