@import '../../assets/scss/vars/vars.sass'
@import '../../assets/scss/mixins/themify.sass'


.ubimax-login-page
  height: 100%
  width: 100%
  background: url('../../assets/images/fcc_login_bg.png')
  background-repeat: no-repeat
  background-size: cover
  background-color: rgb(187, 70, 70)

  .modal-dialog
    top: 0
    height: 100vh

  .logo-wrapper
    height: 25vh
    text-align: center
    padding-top: 10vh

    @include breakpoint-max($bp-medium)
      padding-top: 1vh


    .software-logo
      fill: white
      height: auto
      width: 100%
      max-height: 20vh

      @include breakpoint-max($bp-medium)
        max-width: 50%

  .visual
    height: 70vh
    animation: opacityBG 10s ease

  .footer
    // height: 5vh
    color: $color-white
    text-align: center

    span
      margin: 0 15px
      white-space: nowrap

      @include breakpoint-max($bp-medium)
        margin: 0 5px

      a
        color: white

  .login-wrapper
    background-color: white
    box-shadow: 0 4px 12px rgba($color-grey-900, 0.9)
    display: flex
    align-items: center

    join-call-modal-component,
    ubimax-login-component
      .ubimax-login

        margin: 3 * $spacing-base

        @include breakpoint-max($bp-medium)
          margin: 20px

@keyframes opacityBG
  0%
    opacity: 0
  10%
    opacity: 1
  90%
    opacity: 1
  100%
    opacity: 0

@keyframes gradientBG
  0%
    background-position: 0% 50%
  12.5%
    background-position: 25% 50%
  25%
    background-position: 50% 50%
  37.5%
    background-position: 75% 50%
  50%
    background-position: 100% 50%
  62.5%
    background-position: 75% 50%
  75%
    background-position: 50% 50%
  87.5%
    background-position: 25% 50%
  100%
    background-position: 0 50%

.body-gradient-background
  transition: background 10s ease-out
  height: 100vh

  @include breakpoint-min($bp-medium)

  @include breakpoint-max($bp-medium)
    display: flex
    flex-direction: column
    align-items: center
    justify-content: space-around


  > .row.row-login-content
    change-password-modal-component
      padding: 2 * $spacing-base

    @include breakpoint-min($bp-medium)
      height: 100vh

    > .col-visual
      display: flex
      flex-direction: column
      justify-content: space-between

      ubimax-footer-component
        margin-bottom: $spacing-base

    @include breakpoint-max($bp-medium)
// height: 90vh

@include breakpoint-min-max($bp-small, $bp-large)