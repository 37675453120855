.avatar-name-group
  display: flex
  align-items: stretch
  line-height: 1
  padding: 0
  // height: 100%

  &.two-line-item
    .avatar
      height: 40px
      width: 40px
  .avatar-wrapper
    display: flex
    align-items: center
    position: relative
    .avatar-inner-wrapper
      position: relative
    .avatar
      margin-left: 0
      margin-right: 10px
      display: flex
      align-items: center
      justify-content: center
      @include themify($themes) 
        background-color: rgba(themed('mainColor'), .2)

      i 
        color: $color-white
        font-size: 1.5em

  .item-name
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    display: flex
    flex-direction: column
    align-self: center
  
  .contact-action-button
    display: flex
    align-items: center
    padding: 0 5px
    margin: 0
    height: auto

  &.avatar-name-group-small
    .avatar
      height: 20px
      width: 20px
    .item-name
      padding: 5px 0
    
.avatar
    border-radius: 50%
    height: calc(#{$nav-header-height} - 20px)
    text-align: center
    width: calc(#{$nav-header-height} - 20px)
    margin-left: 10px
    overflow: hidden
    position: relative

    img
      height: 100%
      width: 100%


.online-status
  position: absolute
  bottom: -3px
  right: 3px
  .status-icon
    text-shadow: -1px 0 $color-white, 0 1px $color-white, 1px 0 $color-white, 0 -1px $color-white

.is-offline, .two-line-item
  .status-icon
    color: $color-offline

.is-busy
  .status-icon
    color: $color-busy

.is-available
  .status-icon
    color: $color-available


.avatar-name
    margin: 0
    line-height: 1.1
    margin-bottom: 2px
    margin-top: 3px
    
    
.additional-info
  margin: 0
  color: $color-grey-500
  font-size: 12px


.avatar-list
  width: 100%
  height: 100%
  margin-left: auto
  margin-right: auto
  display: grid
  align-items: center
  grid-template-columns: repeat(auto-fit,  minmax(5px, max-content))
  line-height: 20px
  list-style: none
    
  .avatar-item
    background-color: #ccc
    border-radius: 50%
    height: 20px
    text-align: center
    width: 20px
    overflow: hidden
    position: relative
    margin-left: -5px
  .list-more
    padding: 2px 6px
    font-size: 12px
    font-weight: 400
    border-radius: 4px
    color: #ffffff
    background: #9F9F9F
  .list-dots
    margin-left: 5px
    margin-right: 5px

