tags-input
    .host
        .tags
            border-bottom: 1px solid #cccccc !important
            border: none
            box-shadow: none
            border-radius: 0px

            &.focused
                border: none
                // @include themify($themes)
                //     border-color: themed(mainColor)
                border-bottom: 1px solid #cccccc
                border-radius: 0
                box-shadow: none
            
            input
                padding: 0px
            .tag-item
                margin-right: 5px
                padding: 2px 6px
                font-size: 12px
                font-weight: 400
                border-radius: 4px
                color: $tag-text-color
                box-shadow: none
                line-height: $line-height
                border: none
                @include themify($themes)
                    background-color: themed(mainColor)
                margin: 3px 5px 3px 0
                font-family: $font-family
                height: auto

                a 
                    display: inline-block
                    color: $color-white
                .remove-button
                    font-weight: 100
