/**
  * title: File Upload Button
  * desc: Nice looking button for the upload of files
  * showSideBySideWidth: col-sm-4
  * 
  * htmlCodeVariations:
  * - title: Show simple use case
  *   code: |
  *          <file-upload-button on-select="$ctrl.myFunction(files)" accept=".jpeg" button-text="Hellooooo!!">
  *          </file-upload-button>
  * 
  * usage:
  * - when to use:
  *   description: |
  *                When you want a nice looking upload button
  * 
  * attributes:
  * - attributeName: on-select
  *   type: function
  *   required: yes
  *   description: typically a function in the parent component to add the files to the component.
  * - attributeName: accept
  *   type: string
  *   required: no
  *   description: the file extension which is accepted
  * - attributeName: button-text
  *   type: string
  *   required: no
  *   description: To customise the text. Default text is "Custom Upload"
  * - attributeName: input-class
  *   type: string
  *   required: no
  *   description: to customise the look of the button
  */

.files-to-upload 
    display: flex
    justify-content: space-between

    i.icon
        margin-top: 5px