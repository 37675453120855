/**
  * ---
  *
  * title: Cards
  * desc:
  * showSideBySideWidth: col-sm-4
  * htmlCodeVariations:
  *
  * - title: Card with Image and Card Action Buttons
  *   code: |
  *              <div class="card">
  *                <div class="image-wrapper aspect-ratio-16-9 image-cover">
  *                  <div class="image ">
  *                    <img src="src/assets/images/placeholder/placeholder01.png">
  *                  </div>
  *                </div>
  *                  <div class="card-content">
  *                    <h3 class="card-title">GlassEE 1</h3>
  *                    <p><i class="icon icon-user"></i> Tante Mathilda</p>
  *                  </div>
  *                  <ul class="card-actions">
  *                    <li><button class="button-secondary"> <i class="icon icon-phone"></i> Send Message</button></li>
  *                    <li><button class="button-secondary"><i class="icon icon-send"></i> Call</button></li>
  *                  </ul>
  *                </div>
  * - title: Card Text Only and Card Action Buttons
  *   code: |
  *              <div class="card">
  *                <div class="card-content">
  *                  <h3 class="card-title">Example Card 2</h3>
  *                  <p>This is an example of a card. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Est quasi ipsam sint alias, nemo. Dolore minus aliquid, modi debitis adipisci vel amet, sit officia placeat?
  *                  </p>
  *                </div>
  *                <ul class="card-actions">
  *                  <li><button class="button-secondary"> <i class="icon icon-phone"></i> Send Message</button></li>
  *                  <li><button class="button-secondary"><i class="icon icon-send"></i> Call</button></li>
  *                </ul>
  *              </div>
  * usage:
  *  - title: When to use
  *    description:
  *
  * attributes:
  * - attributeName:
  *   type:
  *   required:
  *   description:
  *   default:
  *
  * classes:
  * - className:
  *   required :
  *   description:
  *
  * fineprint:
  *   version:
  *   update:
  *   owner: Magdalena Riecken
  * ---
  */
// CARDS
// ==============================

$card-shadow: true !default
$card-title-font-size: 24px !default
$card-bg-color: $color-white !default

.card
  background: $card-bg-color
  overflow: hidden
  display: flex
  flex-direction: column
  padding-bottom: calc($spacing-base/2)
  position: relative

  @if $card-shadow
    box-shadow: 0 2px 6px 0 hsla(0, 0%, 0%, .1)

  &:hover

    .remove-card-icon
      display: block
      position: absolute
      right: 5px
      top: 5px
      z-index: 1
      color: $color-white

.card-hover
  cursor: pointer

  &:hover
    transform: scale(1.05)

.remove-card-icon
  display: none

.card-content-wrapper
  height: 100%
  display: flex
  flex-direction: column
  padding: calc($spacing-base/2) $spacing-base

.card-content
  flex-grow: 1
  padding: calc($spacing-base/2) $spacing-base

  p
    margin: 0

.card-title
  //font-size: $card-title-font-size
  margin-bottom: 5px
  font-weight: $font-weight-semibold
  @include text-ellipsis

.card-image
  img
    max-width: 100%


.card-actions
  display: flex
  flex-flow: row wrap
  align-items: center
  margin-top: calc($spacing-base/2)
  margin-bottom: 0
  list-style: none
  justify-content: flex-end
  padding: 0 $spacing-base calc($spacing-base/2)

  & > *
    margin-bottom: 0

.card-grid-same-height
  .card
    height: 100%

  .card-content
    height: 100%

.card-grid-gallery
  &.row
    .col
      padding: 0 calc($spacing-base/2)


$colamounts: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12)

@each $col in $colamounts
  .card-grid-#{$col}-cols
    > .col
      flex-basis: #{100% / $col}
      max-width: #{100% / $col}







