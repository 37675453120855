/*
 * Color Definition per app solution
 */

$themes: (
  default: (
    mainColor: $default-primary, 
    secondaryColor: $default-secondary,
    gradientStart: $default-primary,
    gradientEnd: $default-gradient
  ),
  x-pick: (
    mainColor: $xpick-primary, 
    secondaryColor: $xpick-secondary,
    gradientStart: $xpick-primary,
    gradientEnd: $xpick-gradient
  ),
  ai-studio: (
    mainColor: $aistudio-primary, 
    secondaryColor: $aistudio-secondary,
    gradientStart: $aistudio-primary,
    gradientEnd: $aistudio-gradient
  ),
  frontline-creator: (
    mainColor: $creator-primary, 
    secondaryColor: $creator-secondary,
    gradientStart: $creator-primary,
    gradientEnd: $creator-gradient
  ),
  x-make: (
    mainColor: $xmake-primary, 
    secondaryColor: $xmake-secondary,
    gradientStart: $xmake-primary,
    gradientEnd: $xmake-gradient
  ),
  admin: (
    mainColor: $admin-primary, 
    secondaryColor: $admin-secondary,
    gradientStart: $admin-primary,
    gradientEnd: $admin-gradient
  ),
  x-assist: (
    mainColor: $xassist-secondary,
    secondaryColor: $xassist-secondary, 
    gradientStart: $xassist-primary,
    gradientEnd: $xassist-gradient
  ),
  x-inspect: (
    mainColor: $xinspect-primary, 
    secondaryColor: $xinspect-secondary,
    gradientStart: $xinspect-primary,
    gradientEnd: $xinspect-gradient
  )


);
