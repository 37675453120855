


/**
  * ---
  *
  * title: Multiselect with search
  * desc: For Basic Documentation checkout http://dotansimha.github.io/angularjs-dropdown-multiselect/docs/#/main
  * showSideBySideWidth: col-sm-10
  *
  * htmlCodeVariations:
  * - title: Show Selected Options 
  *   code: |
  *    <ng-dropdown-multiselect-extended
  *              class="col col-sm-12"
  *              options="teams4"
  *              extra-settings="multiselectDropdownSettings"
  *              translation-texts="rolesDropdownTexts"
  *              selected-model="selectedData.teams"
  *              label="Add Members">
  *    </ng-dropdown-multiselect-extended>
  *       <div class="selected-item" ng-repeat="item in selectedData.teams">
  *          <label>{{item.name}}</label>
  *          <a href="" ng-click="removeElement($index, item)"><i class="icon icon-times"></i></a>
  *      </div>
  *
  * jsCodeVariations:
  * - title: Set Up
  *   code: |
  *      $scope.teams4 = [{"id":1,"name":"Ubimax Nord", "userCount":4, "email": "ubimax.nord@ubimax.com"},
  *                       {"id":2,"name":"Ubimax Ost","userCount":4, "email": ""},
  *                       {"id":3,"name":"Ubimax Süd", "userCount":1,"email": "ubimax.süd@ubimax.com"},
  *                       {"id":4,"name":"Ubimax West", "userCount":1, "email":""}];
  *
  *
  *      $scope.multiselectDropdownSettings = {
  *          styleActive: true,
  *          closeOnBlur: true,
  *          checkBoxes: false,
  *          displayProp: 'name',
  *          enableSearch: false,
  *          idProperty: 'name',
  *          externalIdProp: 'name',
  *          showCheckAll: true,
  *          showUncheckAll: false,
  *          closeOnSelect: false,
  *          closeOnDeselect: false,
  *          preSelectItem: true,
  *          alternativeKeyboardControls: true,
  *          selectedToTop: false,
  *          showTags: false,
  *          searchOnButton: true,
  *          hideSelectedOnes: true,
  *          showCheckboxes: false,
  *     
  *      }
  *
  *      $scope.removeElement = function ($index, item) {
  *          $scope.selectedData.teams.splice($index, 1);
  *         
  *      }
  * usage:
  *  - title: When to use
  *    description: |
  *                   Ubimax uses a customized version of ng-dropdown-multiselect. For original documentation see: http://dotansimha.github.io/angularjs-dropdown-multiselect/docs/#/main. Following Attributes are added.
  * 
  * attributes:
  * - attributeName: label
  *   type: string 
  *   required: no
  *   description: The label to be shown above the Dropdown 
  *   default: null
  * - attributeName: showTags 
  *   type: boolean
  *   required: no
  *   description: Defines if the selected options will be shown as Tags inside the Dropdown.
  *   default: false
  * - attributeName: tagsMaxItems
  *   type: integer
  *   required: no
  *   description: Defines how many tags will be shown. The remaining tags will be represented by a tag including the number of the remaining items. If not specified unlimited tags will be shown. 
  *   default: unlimited
  * - attributeName: hideSelectedOnes
  *   type: boolean
  *   required: no
  *   description: Is used to hide the selected items of the list.
  *   default: false
  * - attributeName: searchOnButton
  *   type: boolean
  *   required: no
  *   description: Is used to display the search on the dropdown.
  *   default: false
  * - attributeName: showCheckboxes
  *   type: boolean
  *   required: no
  *   description: Is used to display the checkboxes.
  *   default: false
  *  
  * classes:
  * - className:
  *   required : 
  *   description: 
  *
  * fineprint:
  *   version: 
  *   update: 
  *   owner: Angsumita Nath
  * ---
  */
multiselect
  display: block
  > .btn-group
    min-width: 180px
  .btn
    width: 100%
    background-color: #FFF
    &.has-error
      border: 1px solid #a94442 !important
      color: #db524b
  .dropdown-options
    max-height: 300px
    min-width: 200px
    width: 100%
    overflow-y: auto
    .filter
      > input
        width: 99%
      .glyphicon
        cursor: pointer
        pointer-events: all
        display: none
    width: 100%
    box-sizing: border-box
    padding: 2px

    > li > a
      padding: 3px 10px
      cursor: pointer
      i
        margin-right: 4px
  > .btn-group > button
    padding-right: 20px
    > .caret
      border-left: 4px solid transparent
      border-right: 4px solid transparent
      border-top: 4px solid black
      right: 5px
      top: 45%
      position: absolute




.glyphicon-none:before
  content: "\e013"
  color: transparent !important


.btn-group, .btn-group-vertical
  position: relative
  display: inline-block
  vertical-align: middle


.btn-group > .btn:first-child
  margin-left: 0


multiselect .btn.has-error
  border: 1px solid #a94442 !important
  color: #db524b


.btn-group > .btn, .btn-group-vertical > .btn
  position: relative
  float: left


multiselect > .btn-group > button
  padding-right: 20px


multiselect .btn
  width: 100%
  background-color: #FFF

.btn-default
  color: #333
  background-color: #fff
  border-color: #ccc

.dropdown-header 
  margin: 0 15px 15px 15px
.dropdown-options
  position: absolute
  top: 100%
  left: 0
  z-index: 1000
  display: none
  float: left
  min-width: 160px
  padding: 15px 0
  margin: 2px 0 0
  font-size: 14px
  text-align: left
  list-style: none
  background-color: #fff
  -webkit-background-clip: padding-box
  background-clip: padding-box
  border: 1px solid #ccc
  border: 1px solid rgba(0, 0, 0, 0.15)
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175)
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175)
  margin-top: -1px
  width: 100%

  .empty-state
    flex-wrap: wrap
    flex-direction: column

    h2
      font-size: 18px

    .action-link
      @include themify($themes)
        color: themed('mainColor')
      text-transform: uppercase
      border: 2px solid

  > li > a
    display: block
    padding: 8px 20px
    clear: both
    font-weight: normal
    line-height: 1.42857143
    color: #333
    white-space: nowrap
  > li.disabled
      > a, a:hover
        color: $color-grey-300
        opacity: 1
        .check-icon
          color: $color-grey-300


li.active:last-of-type 
  //border-bottom: 1px solid red
li.isActive
  background-color: #E8E9E8

  .avatar-name
    font-weight: bold
    color: #68686e

.tag-number-indicator
  margin-left: auto

.align-right
  flex: 0 0 0%
  align-self: center

.multiselect-parent
    width: 100%
    overflow: unset

    .tag-search
      margin: auto 0 
      width: 30%

    .search-input
      border: none 
      padding: 1% 0
        
    .list-display
      display: flex
      width: 100%

    .width100 
      width: 100%

    .checkbox-parent
      padding: 0
      margin: auto

    .hover-color
      background: #f5f5f5

    .search-style
      width: 100%

    .selectAll
      padding-left: 3%

    .form-field-input
      height: auto
      display: flex
    
    .button-text
      display: flex
      justify-content: space-between
      align-items: center

      .button-template
        flex-grow: 2

    .tags, .button-text
      flex: 1 0 0%

    .button-wrapper
      width: 100%

    .margin0
      margin: 0

    .check-icon
      @include themify($themes)
          color: themed('mainColor')
      padding: 0.5% 2% !important

    .dropdown-toggle
        border: none
        padding: 0
        width: 100%
        text-align: left
        color: $color-grey-900
        text-transform: none
        margin-bottom: 0
        height: auto
        margin: 0
        padding: 0
        letter-spacing: 0
        display: flex

        z-index: -12
        line-height: 1
        margin-top: 9px
        &:hover
          background-color: transparent

        .caret:before
          font-family: 'iconfont'
          content: '\ea02'
          float: right

   
ng-dropdown-multiselect
  &.bg-grey-10
    button.dropdown-toggle
      background-color: $color-grey-10
  .additional-action-button
    margin-left: 8px

.users
  .avatar
    min-width: 35px !important
    min-height: 35px !important

  .avatar-name-group.two-line-item .avatar
      width: 35px !important
      height: 35px !important


.tag
  .avatar-wrapper 
    .avatar 
      min-height: 25px !important
      min-width: 25px !important

.selected-item
  margin: 8px 16px
  padding: 1%
  display: flex
  align-items: center
  justify-content: space-between
    
  .additional-info
    line-height: 15px

.selected-item:hover 
  background: $admin-gradient
  cursor: default


        
          
