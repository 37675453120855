/**
  * ---
  *
  * title: Notifications
  * desc:
  *
  * htmlCodeVariations:
  * - title: Full Screen Loading Overlay
  *   code: <button-primary type="submit" text="Full SCreen Loading Overlay" ng-click="showLoadingOverlayFullScreen();"/>
  *   doNotPrintCodeExample: true
  * - title: Modal Loading Overlay
  *   code: <button-primary type="submit" text="Modal Loading Overlay" ng-click="showLoadingOverlayDomElement();"/>
  *   doNotPrintCodeExample: true
  *
  * jsCodeVariations:
  * - title: Full Screen Loading Overlay
  *   code: |
  *             function UsersController(...,  loadingService, ...) {  // Inject service into controller
  *
  *                 loadingService.show('Loading User ...' );               // Overlay will be attached to body
  *                 // Load data here
  *                 loadingService.hide();
  *
  *
  *             };
  *
  *
  *
  * - title: Attach Loading Overlay to DOM Element, e.g. Modal
  *   code: |
  *
  *           function ExampleModalController(..., $element, loadingService, ...) {
  *
  *             let $ctrl = this;
  *
  *             $ctrl.$onInit = async function () {
  *
  *                  loadingService.show('Message ... ', $element)          // Overlay will be attached to the Modal DOM $element
  *                  // Load data here
  *                  loadingService.hide();
  *                  }
  *             };
  *           };
  *
  *
  *
  *
  * usage:
  *  - title: When to use
  *    description:
  *
  * attributes:
  * - attributeName:
  *   type:
  *   required:
  *   description:
  *   default:
  *
  * ---
  */
.noty_theme__mint
    background-color: #fff
    border-bottom: 1px solid #D1D1D1
    color: #2F2F2F

#noty_layout__topRight
    top: 75px !important
    width: fit-content !important
    right: 30px !important

.noty_bar
    box-shadow: 0 8px 16px 0 rgba(0,0,0,.1)
    padding: 24px
    background-color:  #F9F9F9
    margin-bottom: 24px !important
    &:hover
        .noty_close_button
            display: block !important

    .noty_close_button
        font-size: 25px !important
        line-height: 25px !important
        position: absolute
        right: 10px !important
        top: 10px !important
        color: $color-grey-900 !important
        display: none !important
        background-color: transparent !important
        border-radius: 0 !important
        font-weight: normal !important
        &:hover
            opacity: 0.75
            transform: scale(1.08)

    .noty_body
        display: flex
        align-items: center
        padding: 0 !important

        .avatar
            margin-left: 0
            margin-right: 10px
            height: 41px
            width: 41px
            animation: incoming-call-animation 2s ease infinite

        .noty_content
            white-space: pre
            font-weight: 300
        .noty_buttons
            margin-left: auto
            .icon
                color: white
                font-size: 17px
                padding: 9px
                border-radius: 50%
                margin-left: 14px
                display: inline-block
                cursor: pointer
                &:hover
                    opacity: 0.75
                    transform: scale(1.08)
            .red
                background-color: $color-no

            .green
                background-color: $color-yes
            .rotate-135
                transform: rotate(135deg)
                &:hover
                    opacity: 0.75
                    transform: scale(1.08) rotate(135deg)

        h3
            font-size: 18px
            line-height: 18px
            margin-bottom: 0
            font-weight: 400

    .noty_body_im
        display: flex
        flex-direction: column

        .noty_image_title
            display: flex
            flex-direction: row
            padding-right: 70px

        .noty_content_im
            font-weight: 300

        .noty_image
            img
                height: 40px
                width: auto
                margin-right: 20px
        h3
            line-height: 33.5px
        hr
            width: 140%
            margin-bottom: 10%
            margin-left: -24px

@keyframes incoming-call-animation

    0%
        transform: scale(1)

    15%
        box-shadow: 0 0 0 5px rgba($xassist-secondary, 0.2)

    25%
        box-shadow: 0 0 0 10px rgba($xassist-secondary, 0.2), 0 0 0 20px rgba($xassist-secondary, 0.1)

    35%
        box-shadow: 0 0 0 15px rgba($xassist-secondary, 0.2), 0 0 0 30px rgba($xassist-secondary, 0.1)

.warning-sign
    position: absolute
    width: 150px
    height: 150px



