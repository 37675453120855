body
    ::-webkit-scrollbar-track
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3)
        background-color: #F5F5F5


    ::-webkit-scrollbar
        width: 8px
        height: 8px
        background-color: #F5F5F5

    ::-webkit-scrollbar-thumb
        background-color: #000000

    .scrollbar-light
        ::-webkit-scrollbar-track
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.0)
            background-color: transparent

        ::-webkit-scrollbar
            width: 8px
            height: 8px
            background-color: #F5F5F5

        ::-webkit-scrollbar-thumb
            background-color: $color-grey-300
            border-radius: 8px
    
    
    
.ag-theme-material
    &::-webkit-scrollbar 
        width: 40px
        height: 15px 

    &::-webkit-scrollbar-track 
        background: transparent


    &::-webkit-scrollbar-thumb 
        border-left: 32px solid white
    
    



