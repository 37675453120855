
// COLOR Ubimax

//$color-grey-900: #454545 !default
//$color-grey-800: #5C5C5C !default
//$color-grey-700: #727272 !default
//$color-grey-600: #898989 !default
//$color-grey-500: #9F9F9F !default
//$color-grey-400: #B6B6B6 !default
//$color-grey-300: #CCCCCC !default
//$color-grey-200: #E3E3E3 !default
//$color-grey-100: #F9F9F9 !default
//$color-grey-50:  #ECEFF1 !default
$color-grey-10:  #F5F5F5 !default

//=========================================
//New ai-studio grey ramp colors
$color-grey-950: #141414 !default
$color-grey-900: #212121 !default
$color-grey-800: #292929 !default
$color-grey-700: #303030 !default
$color-grey-600: #404040 !default
$color-grey-500: #6E6E6E !default
$color-grey-400: #919191 !default
$color-grey-300: #ACACAC !default
$color-grey-200: #C8C8C8 !default
$color-grey-100: #E1E1E1 !default
$color-grey-50:  #F1F1F1 !default
$color-grey-25:  #F8F8F8 !default

//=========================================


//UBIMAX THMEME COLORS

$default-primary:           #B90E0C !default
$default-secondary:         #7A0907 !default
$default-gradient:          #DC8685 !default

$admin-primary:             #3F4040 !default
$admin-secondary:           #7B7B7C !default
$admin-gradient:            #E8E9E8 !default

$xassist-primary:           #4EC1D1 !default
$xassist-secondary:         #368791 !default
$xassist-gradient:          #A6E0E8 !default

$xpick-primary:             #FF7A1C !default
$xpick-secondary:           #803D0E !default
$xpick-gradient:            #FFBB8C !default

$xmake-primary:             #FFB427 !default
$xmake-secondary:           #805A13 !default
$xmake-gradient:            #FFD992 !default

$xinspect-primary:          #5EB98B !default
$xinspect-secondary:        #3E7A5C !default
$xinspect-gradient:         #AEDCC5 !default

$creator-primary:           #8680BF !default
$creator-secondary:         #5A5580 !default
$creator-gradient:          #C2BFDF !default

//Old ai-studio colors
//$aistudio-primary:          #008695 !default
//$aistudio-secondary:        #004C54 !default
//$aistudio-gradient:         #7FC2CA !default

//=========================================
//New ai-studio colors
$aistudio-primary-shade-30: #015189 !default
$aistudio-primary-shade-20: #026DBA !default
$aistudio-primary-shade-10: #0281DC !default
$aistudio-primary:          #028EF2 !default
$aistudio-primary-tint-10:  #209DF6 !default
$aistudio-primary-tint-20:  #65BBF9 !default
$aistudio-primary-tint-30:  #B1DDFC !default

//Altenative secondary color
$aistudio-secondary:        $color-grey-700 !default
//Conceptional secondary color
//$aistudio-secondary:      #8378FCFF !default
$aistudio-gradient:         #8378FC !default

//=========================================

// COLOR VARS
// ==============================

$color-white: #ffffff !default
$color-black: #000000 !default
$color-shiraz: #ad003a !default

$color-available: #4BC36B !default
$color-busy: #E44B4B !default
$color-offline: #9F9F9F !default
$color-close: #d74654 !default

$color-no: #E44B4B !default
$color-yes: #38C972 !default


$color-blue-gray-500: #607d8b !default
$color-blue-gray-800: #37474f !default
$color-red-200: #ef9a9a !default
$color-red-500: #C54B50 !default
$color-red-900: #b71c1c !default
$color-blue-500: #2196f3 !default
$color-green-900: #1b5e20 !default



$color-success: #4bc36b !default
$color-error:   #e44b4b !default
$color-warning: #ffca0c !default
//icon-colors:
$color-monaco-icon-purple:      #ab47bc
$color-monaco-icon-pink-01:     #ec407a
$color-monaco-icon-pink-02:     #d73b6f
$color-monaco-icon-red-01:      #e53935
$color-monaco-icon-red-02:      #ff5252
$color-monaco-icon-orange-01:   #f57f17
$color-monaco-icon-orange-02:   #fba919
$color-monaco-icon-yellow-01:   #ffca28
$color-monaco-icon-green-01:    #afb42b
$color-monaco-icon-green-02:    #76b900
$color-monaco-icon-green-03:    #81c784
$color-monaco-icon-green-04:    #4caf50
$color-monaco-icon-green-05:    #43a047
$color-monaco-icon-green-06:    #00bfa5
$color-monaco-icon-blue-01:     #26c6da
$color-monaco-icon-blue-02:     #27a9e4
$color-monaco-icon-blue-03:     #448aff
$color-monaco-icon-blue-04:     #235997
$color-monaco-icon-grey-01:     #78909c
$color-monaco-icon-grey-02:     #b0bec5
$color-monaco-icon-brow:        #8d6e63
//monaco-editor colors:
$color-monaco-grey-dark-10: #1e1e1e
$color-monaco-grey-dark-20: #252525
$color-monaco-grey-dark-30: #252526
$color-monaco-grey-dark-40: #2a2d2e
$color-monaco-grey-dark-50: #2d2d2d
$color-monaco-grey-dark-60: #313232
$color-monaco-grey-dark-70: #37373d
$color-monaco-grey-dark-80: #323233
$color-monaco-grey-dark-90: #555555
$color-monaco-grey-dark-100: #969696
$color-monaco-grey-dark-110: #9F9F9F
$color-monaco-grey-dark-120: #fefefc
$color-monaco-grey-light-10: #ffffff
$color-monaco-grey-light-20: #f3f3f3
$color-monaco-grey-light-30: #f3f3f3
$color-monaco-grey-light-40: #e8e8e8
$color-monaco-grey-light-50: #ececec
$color-monaco-grey-light-60: #dcdcdc
$color-monaco-grey-light-70: #e4e6f1
$color-monaco-grey-light-80: #dddddd
$color-monaco-grey-light-90: #555555
$color-monaco-grey-light-100: #969696
$color-monaco-grey-light-110: #616161
$color-monaco-grey-light-120: #1e1e1e
$color-monaco-action-bar-background-dark: #3c3c3c
$color-monaco-action-bar-font-color-dark: #f0f0f0
$color-monaco-action-bar-item-hover-dark: #094771
$color-monaco-action-bar-item-hover-font-dark: #f0f0f0
$color-monaco-action-bar-box-shadow-dark: rgb(0 0 0 / 36%) 0px 2px 4px
$color-monaco-action-bar-background-light: #ffffff
$color-monaco-action-bar-font-color-light: #616161
$color-monaco-action-bar-item-hover-light: #0060c0
$color-monaco-action-bar-item-hover-font-light: #ffffff
$color-monaco-action-bar-box-shadow-light: rgb(0 0 0 / 16%) 0px 2px 4px




