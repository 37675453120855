// PAGINATION
// ==============================

$pagination-bg-color: $color-white !default
$pagination-active-color: $brand-color !default
$pagination-border-color: $color-grey-300 !default

.pagination-wrapper
    display: flex
    list-style: none
    align-items: center
    justify-content: center
    bottom: 0
    position: absolute
    background: $color-grey-25
    padding: 15px
    left: 30%
    right: 30%
    border-radius: 7px
    box-shadow: -2px 3px 162px 53px rgb(0 0 0 / 51%)
    margin-bottom: 30px
    border: 1px solid $color-grey-200

    .pagination
        margin-bottom: 0px

        .page-item
            margin: 0 5px

            .page-link
                display: block
                padding: 5px 10px
                border-radius: 5px
                background: $pagination-bg-color
                border: 1px solid $pagination-border-color

                &:active,
                &:focus,
                &:hover
                    opacity: 1
                    border-color: $aistudio-primary
                    background: $aistudio-primary
                    color: $pagination-bg-color

                &:active
                    transform: scale(0.95)

                .sr-only
                    display: none

        .active
            .page-link
                background: $aistudio-primary
                color: $color-white
                border: 1px solid $aistudio-primary

        &-page-size
            display: flex
            align-items: center
            margin-left: 10px

            p, span
                margin-bottom: 0
                font-size: 16px
                font-weight: 400
                @include themify($themes)
                    color: themed('secondaryColor')

            &-item
                width: auto !important

            .ng-select
                //padding: 0
                width: 75px

                .ng-option-selected
                    span
                        color: $aistudio-primary

    .ng-select-container
        min-height: auto !important
        margin-right: 30px

        &:after
            //border: 1px solid #E3E3E3 !important

    .page-size-select
        display: flex
        flex-direction: row
        align-items: center

        ng-select
            top: 4px

        p
            margin-bottom: 0
            padding: 0 15px

        .pagination-page-size-item
            width: 65px

