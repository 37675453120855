$dropdown-bg:                    $color-white !default
$dropdown-border:                rgba(0, 0, 0, .15) !default
$dropdown-divider-bg:            #e5e5e5 !default

$dropdown-link-color:            $font-color !default
$dropdown-link-hover-color:      $font-color !default
$dropdown-link-hover-bg:         $color-grey-200 !default

$dropdown-link-active-color:     $color-grey-100 !default
$dropdown-link-active-bg:        $color-grey-100 !default
$dropdown-link-disabled-color:   $color-grey-100 !default

$dropdown-header-color:          $color-grey-100 !default

$dropdown-caret-color:           #000 !default

$caret-width-base:          4px !default
$zindex-dropdown:          1000 !default

$border-radius-base:        4px !default
$line-height-base:        1.428571429 !default // 20/14
$cursor-disabled:                not-allowed !default
$font-size-small:         ceil(($font-size-base * .85)) !default // ~12px


/**
  * ---
  *
  * title: Dropdown
  * desc: For Basic Documentation checkout http://dotansimha.github.io/angularjs-dropdown-multiselect/docs/#/main
  * showSideBySideWidth: col-sm-10
  *
  * htmlCodeVariations:
  * - title: Show Selected Options as Tags
  *   code: |
  *          <ng-dropdown-multiselect
  *            class="col col-sm-12"
  *            options='teams'
  *            extra-settings="teamsDropdownSettings"
  *            translation-texts="teamsDropdownTexts"
  *            selected-model="selectedUser.teams"
  *            label="Teams" showTags="true">
  *          </ng-dropdown-multiselect>
  *
  * - title: Use a custom template in Selected Option
  *   code: |
  *          <ng-dropdown-multiselect
  *            class="col col-sm-12"
  *            options='teams2'
  *            extra-settings="teamsDropdownSettings2"
  *            translation-texts="teamsDropdownTexts"
  *            selected-model="selectedUser2.teams"
  *            label="Use a custom template in Selected Option">
  *          </ng-dropdown-multiselect>
  * - title: Empty state
  *   code: |
  *          <ng-dropdown-multiselect
  *           class="col col-sm-12"
  *           options="teams3"
  *           extra-settings="teamsDropdownSettings3"
  *           translation-texts="teamsDropdownTexts3"
  *           selected-model="selectedUsers3.teams"
  *           label="oh no! there are no teams">
  *          </ng-dropdown-multiselect>
  *
  * jsCodeVariations:
  * - title: Set Up
  *   code: |
  *          $scope.teams = [{"id":1,"name":"Ubimax Nord", "userCount":4},
  *                          {"id":2,"name":"Ubimax Ost","userCount":2},
  *                          {"id":3,"name":"Ubimax Süd","userCount":3},
  *                          {"id":4,"name":"Ubimax West", "userCount":1}];
  *
  *          $scope.teamsDropdownSettings = {
  *            styleActive: true,
  *            closeOnBlur: true,
  *            checkBoxes: false,
  *            displayProp: 'name',
  *            enableSearch: true,
  *            showCheckAll: false,
  *            showUncheckAll: false,
  *            closeOnSelect: false,
  *            closeOnDeselect: false,
  *            preSelectItem: true,
  *            idProperty: 'id',
  *            externalIdProp: 'id',
  *            keyboardControls: true,
  *            selectedToTop: true,
  *            showTags: true,
  *            tagsMaxItems: 3,
  *            scrollableHeight: '300px',
  *            scrollable: true
  *          };
  *
  *
  *          $scope.teamsDropdownTexts = {
  *            checkAll: 'Check All',
  *            uncheckAll: 'Uncheck All',
  *            selectionCount: 'checked',
  *            selectionOf: '/',
  *            searchPlaceholder: 'Search...',
  *            buttonDefaultText: 'Add Teams',
  *            dynamicButtonTextSuffix: 'checked'
  *          };
  *
  * - title: Custom Template in Button
  *   code: |
  *          $scope.teams2 = [{"id":1,"name":"Ubimax Nord", "userCount":4},
  *                          {"id":2,"name":"Ubimax Ost","userCount":2},
  *                          {"id":3,"name":"Ubimax Süd","userCount":3},
  *                          {"id":4,"name":"Ubimax West", "userCount":1}];
  *
  *           $scope.teamsDropdownSettings2 = {
  *             styleActive: true,
  *             closeOnBlur: true,
  *             checkBoxes: false,
  *             displayProp: 'name',
  *             enableSearch: true,
  *             closeOnSelect: true,
  *             closeOnDeselect: false,
  *             preSelectItem: true,
  *             idProperty: 'id',
  *             externalIdProp: 'id',
  *             keyboardControls: true,
  *             selectedToTop: true,
  *             scrollableHeight: '300px',
  *             scrollable: true,
  *             template: '<b>{{option.name}} <span class="tag tag-green"><i class="icon icon-beer"></i> online</span> </b>',
  *             useTemplateForSelectedOption: true,
  *             selectionLimit: 1
  *           };
  *
  *
  *          $scope.teamsDropdownTexts = {
  *            checkAll: 'Check All',
  *            uncheckAll: 'Uncheck All',
  *            selectionCount: 'checked',
  *            selectionOf: '/',
  *            searchPlaceholder: 'Search...',
  *            buttonDefaultText: 'Add Teams',
  *            dynamicButtonTextSuffix: 'checked'
  *          };
  *
  * - title: Empty State in dropdown
  *   code: |
  *          $scope.teams3 = [];
  *          $scope.teamsDropdownSettings3 = {
  *            showTags: true,
  *            displayProp: 'name',
  *            idProperty: 'id',
  *            actionLink: 'teams',
  *            actionClose: () => this.close() // Required for closing modals
  *          };
  *          $scope.teamsDropdownTexts3 = {
  *            emptyState: 'teams'
  *          };
  *
  * usage:
  *  - title: When to use
  *    description: |
  *                   Ubimax uses a customized version of ng-dropdown-multiselect. For original documentation see: http://dotansimha.github.io/angularjs-dropdown-multiselect/docs/#/main. Following Attributes are added.
  *
  * attributes:
  * - attributeName: label
  *   type: string
  *   required: no
  *   description: The label to be shown above the Dropdown
  *   default: null
  * - attributeName: showTags
  *   type: boolean
  *   required: no
  *   description: Defines if the selected options will be shown as Tags inside the Dropdown.
  *   default: false
  * - attributeName: tagsMaxItems
  *   type: integer
  *   required: no
  *   description: Defines how many tags will be shown. The remaining tags will be represented by a tag including the number of the remaining items. If not specified unlimited tags will be shown.
  *   default: unlimited
  * - attributeName: useTemplateForSelectedOption
  *   type: boolean
  *   required: no
  *   description: If a custom template is used and should also be used instead of plain text for the selected option, set it to true. This will only work, if selection Limit is 1 and a template for the options is defined.
  *   default: false
  *
  * classes:
  * - className:
  *   required :
  *   description:
  *
  * fineprint:
  *   version:
  *   update:
  *   owner: Magdalena Riecken
  * ---
  */

//
// Dropdown menus
// --------------------------------------------------


.caret
  display: inline-block
  width: 0
  height: 0
  margin-left: 2px
  vertical-align: middle
  border-top: $caret-width-base dashed
  border-top: $caret-width-base solid \9 // IE8
  border-right: $caret-width-base solid transparent
  border-left: $caret-width-base solid transparent

.dropup,
.dropdown
  position: relative

.dropdown-toggle:focus
  outline: 0

.dropdown-menu
  position: absolute
  top: 100%
  left: 0
  z-index: $zindex-dropdown
  display: none
  float: left
  min-width: 200px
  // padding: 5px 0
  margin: 2px 0 0
  font-size: $font-size-base
  text-align: left
  list-style: none
  background-color: $dropdown-bg
  background-clip: padding-box
  border: 1px solid $dropdown-border
  box-shadow: 0 4px 12px rgba($color-grey-900, 0.5)

  &.pull-right
    right: 0
    left: auto

  .divider
    height: 1px
    margin: 9px 0
    overflow: hidden
    background-color: $color-grey-200
  li
      padding: 0
      display: flex
  > li > a
    padding: 7.5px 20px
    clear: both
    font-weight: 400
    line-height: $line-height-base
    // color: $dropdown-link-color
    @include themify($themes)
      color: themed(mainColor)
    white-space: nowrap
    flex-grow: 1


    &:hover,
    &:focus
      text-decoration: none
      background-color: $dropdown-link-hover-bg
      cursor: pointer




.dropdown-menu > .active > a
  &,
  &:hover,
  &:focus
    // color: $dropdown-link-active-color
    text-decoration: none
    background-color: $dropdown-link-active-bg
    outline: 0

.dropdown-menu > .disabled > a
  &,
  &:hover,
  &:focus
    color: $dropdown-link-disabled-color

  &:hover,
  &:focus
    text-decoration: none
    cursor: $cursor-disabled
    background-color: transparent
    background-image: none // Remove CSS gradient
    //@include reset-filter


.open
  > .dropdown-menu
    display: block


  > a
    outline: 0

.dropdown-menu-right
  right: 0
  left: auto

.dropdown-menu-left
  right: auto
  left: 0

.dropdown-header
  display: block
  padding: 3px 20px
  font-size: $font-size-small
  line-height: $line-height-base
  color: $dropdown-header-color
  white-space: nowrap // as with > li > a

.pull-right > .dropdown-menu
  right: 0
  left: auto

.dropup,
.navbar-fixed-bottom .dropdown
  .caret
    content: ""
    border-top: 0
    border-bottom: $caret-width-base dashed
    border-bottom: $caret-width-base solid \9 // IE8

  .dropdown-menu
    top: auto
    bottom: 100%
    margin-bottom: 2px

@media (min-width: 768px)
  .navbar-right
    .dropdown-menu
      right: 0
      left: auto

    .dropdown-menu-left
      left: 0
      right: auto


.dropdown-menu > .dropdown-submenu
  position: relative
  display: flex

  > a
    padding-right: 10px

  > .dropdown-menu
    top: 0
    left: 100%
    margin-top: -6px
    margin-left: -1px


  &:hover
    > .dropdown-menu
      display: block

    > a:after
      border-left-color: #fff

  //> a:after
  //  content:  "\ea04";
  //  font-family: $icon-font-name
  //  float: right

  &.pull-left
    float: none

    > .dropdown-menu
      left: -100%
      margin-left: 10px

.custom-popup-wrapper
  position: relative
  .dropdown-menu.dropdown-menu-typeahead
    display: block
    list-style: none

.ng-option-label, .ng-placeholder
  @include themify($themes)
      color: themed(mainColor) !important
