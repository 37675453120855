// TABS
// ==============================

$tab-active-color: $brand-color !default
$tab-border-color: $color-grey-300 !default
$tab-hover-text-color: $color-grey-800 !default
$tab-active-text-color: $color-grey-600 !default
$tab-inactive-text-color: $color-grey-400 !default


.tab-content
    .tab-pane
        display: none
        &.active
            display: block



.tabs, .action-buttons
    list-style: none
    display: flex
    margin-bottom: 0

    li
        flex: 1
        display: flex
        text-align: center

    .tab
        padding: calc($spacing-base/2) $spacing-base
        border-bottom: 1px solid $tab-border-color
        color: $tab-inactive-text-color
        display: block
        width: 100%

        &.active
            border-top: 2px solid $tab-active-color
            border-left: 1px solid $tab-border-color
            border-right: 1px solid $tab-border-color
            border-bottom: none
            color: $tab-active-text-color


        &:focus,
        &:hover
            opacity: 1
            color: $tab-hover-text-color


.tabbable.tabbable-navigation
    width: 100%

    .tab-bar-wrapper
        margin: 0
        z-index: 3
        display: flex
        justify-content: space-between

    .tabs
        padding: $spacing-base $spacing-base 0 $spacing-base
        li
                margin: 0 $spacing-base
                display: block
                flex: inherit
                margin: 0 7.5px


        .tab
            border-top: none
            border-left: none
            border-right: none
            border-bottom: none
            padding: 3px 0 10px 0


            &.active
                @include themify($themes)
                    color: themed(mainColor)
                    border-bottom: 3px solid themed(mainColor)
                    font-weight: bold
    .action-buttons
        li
            display: flex
            align-items: center
            padding: $spacing-base
            // @include themify($themes)
            //     background-color: themed(mainColor)

            a
                // color: $color-white




