@import '../mixins/base-mixins'
@import '../mixins/themify'
@import '../vars/vars'

.frontline-loading-animation
    height: 50px

.frontline-visual
    fill: none
    stroke-width: 50
    stroke-miterlimit: 10
    stroke-dasharray: 4350
    animation: dash 3s linear infinite
    &.white
        stroke: #FFFFFF

    &.theme-color
        @include themify($themes)
            stroke: themed('secondaryColor')

@keyframes dash
  from
    stroke-dashoffset: 4350
  to
    stroke-dashoffset: -4350


//aistudio-loading-logo animation

.aistudio-loading-animation
    height: 50px

.arrow-1
    fill: $color-grey-600
    stroke: none

    &.theme-color
        fill: var(--aistudio-primary)

.head
    stroke-dasharray: 95
    stroke-dashoffset: -93
    animation: head-animation 3s linear infinite
    animation-direction: forwards
    stroke: $color-grey-600
    stroke-width: 2.75
    stroke-miterlimit: 10
    stroke-linecap: round
    stroke-linejoin: round

    &.theme-color
        stroke: var(--aistudio-primary)



@keyframes head-animation
    0%
        stroke-dashoffset: -93

    50%
        stroke-dashoffset: 0

    100%
        stroke-dashoffset: -93
