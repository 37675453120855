// DISPLAY
// ==============================

$display-up: ('sm' : $bp-small,'md'   : $bp-medium,'lg'   : $bp-large,'xlg' : $bp-xlarge ) !default
$display-down: (    'sm'   : $bp-small,'md'   : $bp-medium,'lg'   : $bp-large,'xlg'  : $bp-xlarge) !default

.display-flex 
    display: flex
    justify-content: space-around


.display-none 
    display: none

@each $name, $bp in $display-up 
    .display-#{$name}-up 
        display: none

        @include breakpoint-min($bp) 
            display: initial

@each $name, $bp in $display-down 
    .display-#{$name}-down 
        display: none

        @include breakpoint-max($bp - 1px)
            display: initial


@each $up-name, $up-bp in $display-up 
    @each $down-name, $down-bp in $display-down 
        @if $up-bp < $down-bp
            .display-#{$up-name}-to-#{$down-name}
                display: none

                @include breakpoint-min-max($up-bp, ($down-bp - 1px))
                    display: initial
