@import '../../../assets/scss/vars/vars.sass'
@import '../../../assets/scss/mixins/themify.sass'

/**
  * ---
  *
  * title: Loading Animation
  * desc: 
  *
  * htmlCodeVariations:
  * - title: Full Screen Loading Overlay
  *   code: <button-primary type="submit" text="Full Screen Loading Overlay" ng-click="showLoadingOverlayFullScreen();"/>
  *   doNotPrintCodeExample: true
  * - title: Modal Loading Overlay
  *   code: <button-primary type="submit" text="Modal Loading Overlay" ng-click="showLoadingOverlayDomElement();"/>
  *   doNotPrintCodeExample: true
  * - title: Full Screen Loading Overlay With Progressbar
  *   code: <button-primary type="submit" text="Full Screen Loading Overlay with Progressbar" ng-click="showLoadingOverlayFullScreenWithProgressBar();"/>
  *   doNotPrintCodeExample: true
  *
  *
  * jsCodeVariations:
  * - title: Full Screen Loading Overlay
  *   code: |
  *             function UsersController(...,  loadingService, ...) {  // Inject service into controller
  *                 
  *                 loadingService.show('Loading User ...' );               // Overlay will be attached to body
  *                 // Load data here
  *                 loadingService.hide();
  *                 
  *                 
  *             };
  *
  *
  * - title: Attach Loading Overlay to DOM Element, e.g. Modal
  *   code: |
  *
  *           function ExampleModalController(..., $element, loadingService, ...) {
  *
  *             let $ctrl = this;
  *
  *             $ctrl.$onInit = async function () {
  *
  *                  loadingService.show('Message ... ', $element)          // Overlay will be attached to the Modal DOM $element
  *                  // Load data here
  *                  loadingService.hide();
  *                  }
  *             };
  *           };
  *
  *
  *
  *
  * - title: Full Screen Loading Overlay with Progressbar
  *   code: |
  *
  *             function UsersController(...,  loadingService, ...) {  // Inject service into controller
  *
  *                 loadingService.show('Uploading', null, ... , current-progress-percentage);               // Overlay will be attached to body
  *                 // The progress percentage needs to be calculated and updated for example like this:
  *                 //        $ctrl.updateProgress = () => {
  *                 //             $current-progress = Math.round(($ctrl.successfulUploads - 1) / ($ctrl.aiResourceUploadService.uploader.queue.length) * 100);
  *                 //             loadingService.show("Image Upload" , null, null, parseInt($current-progress));
  *                 //         };
  *                 // Or like this:
  *                 //     uploadProgressListener(percentage, loadingService, element) {
  *                 //              if(percentage === 100) {
  *                 //                loadingService.show('Processing ...', element, true);
  *                 //            } else {
  *                 //                loadingService.show(`Uploading application`, element, true, percentage.toFixed(1));
  *                 //            }
  *                 //        }
  *                 // Load data here
  *                 loadingService.hide();
  *             };
  *
  *
  *
  *
  * 
  * usage:
  *  - title: When to use
  *    description:
  * 
  * attributes:
  * - attributeName: 
  *   type: 
  *   required:
  *   description: 
  *   default: 
  *  
  * ---
  */

.loading-overlay, .error-overlay
    height: 100%
    width: 100%
    background-color: rgba(0, 0, 0, .9)
    z-index: 1050
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    top: 0
    left: 0

    .progress
        width: 25%
        height: 3px

        .progress-bar
            @include themify($themes)
              background-color: themed('secondaryColor')

    
    &.intransparent
        background-color: rgba(0, 0, 0, 1.0)
        color: $color-white
        
    .loading-message
        margin-top: $spacing-base

        &.loading-message-dark
            color: $color-grey-900
        &.loading-message-bright
            color: $color-grey-10

body
    > .loading-overlay,
    > .error-overlay
        background-color: rgba(0, 0, 0, .9)
        position: fixed

    > .error-overlay
        position: fixed

        .wrapper
            padding-left: $spacing-base
            border-left: 5px solid #7b0505

        #error-headline, #error-message
            color: $color-white

.transparent
    background-color: rgba(0, 0, 0, .9)
    opacity: .9

    .loading-message, .remaining-time-countdown
        color: $color-grey-100


    @keyframes color-change
        0%
            color: $color-white
        50%
            color: $color-grey-600
        100%
            color: $color-white

    .calculating-message
        color: $color-white
        animation: color-change 2s infinite

.intransparent
    background-color: rgba(0, 0, 0, 1.0)
    color: $color-white

.modal-content
    .loading-overlay
        background-color: rgba(255, 255, 255, 1)
        color: $font-color
        position: absolute