.tiles
    display: -webkit-flex
    display: flex
    -webkit-flex-wrap: wrap
    flex-wrap: wrap
    margin-bottom: -15px
    margin-right: -15px
    
    .tiles__container
        margin-right: 15px
        margin-bottom: 15px
        display: flex
        align-items: center
        justify-content: center

        &:hover,  &:focus, &:active
            background-color: $color-grey-10
            opacity: 1



