$line-height: 1.6 !default
$font-weight: 400 !default
$font-weight-semibold: 500 !default
$font-weight-bold: 900 !default
$heading-font-weight: 300 !default
$font-size-base: 14px !default
$font-size-small: 12px !default
$font-color: $color-grey-900 !default
//$font-family:  'Roboto', sans-serif !default
//$heading-font-family: 'Roboto', sans-serif !default

$icon-font-name: 'iconfont'

//New ai-studio font
$font-family:  'SF Pro Text', 'Roboto', sans-serif !default
$heading-font-family: 'SF Pro Text','Roboto', sans-serif !default
