
@import '../../scss/mixins/themify'
@import '../../scss/vars/vars'

/**
  *
  * ---
  *
  * title: Color Picker
  * desc: Allows the user to select a color from a given choice, 
  *       and adjust the thickness of the stroke, if required
  * showSideBySideWidth: col-sm-10
  * 
  * htmlCodeVariations:
  * - title: Show Color and Stroke selection
  *   code: |
  *          <color-stroke-picker
  *              color="$ctrl.requiredColor"
  *              stroke="$ctrl.requiredStroke"
  *              include-stroke>
  *          </color-stroke-picker>
  * 
  * - title: Use only the color picker
  *   code: |
  *           <color-stroke-picker
  *               color="$ctrl.requiredColor2">
  *           </color-stroke-picker>
  * 
  * usage:
  * - when to use:
  *   description: |
  *                 When you want to give the user an easy way to select a color.
  * 
  * attributes:
  * - attributeName: color
  *   type: input
  *   required: yes
  *   description: Variable holding the color afterwards
  * - attributeName: stroke
  *   type: integer
  *   required: no
  *   description: variable for the stroke width
  * - attributeName: include-stroke
  *   type: boolean
  *   required: no
  *   description: if present, show the stroke selection section
  *
  * ---
  */


.color-stroke-picker-wrapper
    display: flex
    align-items: center
    flex-wrap: wrap
    flex-direction: column
    justify-content: center
    position: relative
    min-height: 40px

    .selected-color
        display: flex
        align-items: center
        padding-left: $spacing-base
        padding-right: $spacing-base

        .selected-color-visual
            display: block
            height: 17px
            width: 17px
            border-radius: 50%
            margin-right: $spacing-base
            background-color: $color-grey-200

        .selected-color-input
                -webkit-appearance: none
                background-color: none
                border: 1px solid $color-grey-200
                color: $color-grey-500
                line-height: 0
                width: 80px
                padding: 4px 10px
                border-radius: 5px
                font-weight: 200

    .selectors
        position: absolute
        top: 3em
        z-index: 5
        max-width: 142px
        background-color: #ffffff
        box-shadow: $default-shadow-500

        .color-selector
            display: flex
            flex-wrap: wrap
            flex-direction: row
            padding-top: 8px  // makes a total of 15px with the 7px of the color choice.

        .color-choice
            margin: 7px
            width: 17px
            height: 17px
            border-radius: 50%
        
        .color-choice:last-child
            border: 1px solid #000000

        .color-choice:nth-child(4n)
            margin-right: $spacing-base

        .color-choice:nth-child(4n + 1)
            margin-left: $spacing-base

        .stroke-selector
            padding-bottom: $spacing-base

            .white-stroke
                filter: drop-shadow(0px 3px 6px $color-grey-600)